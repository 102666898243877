import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { AppServices } from 'src/Services/App.Services';
// import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [ToastrService, NgxSpinnerService,]

})
export class RegisterComponent implements OnInit {


  constructor(
     private spinner: NgxSpinnerService,
    private userService: AppServices,
     private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
   this.reactiveForm()
  }

  RegisterForm: FormGroup;
  reactiveForm() {
    this.RegisterForm = this.fb.group({

      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      role: ['', Validators.required],

    });
  }

  Login() {
    this.router.navigateByUrl('/login')
  }


  SubmitForRegister() {

    debugger
    if(this.RegisterForm.valid){
      if(this.RegisterForm.controls.password.value == this.RegisterForm.controls.password_confirmation.value){
    let Data: any;
    this.spinner.show();
    let body = {
      email: this.RegisterForm.controls.email.value,
      name: this.RegisterForm.controls.name.value,
      password: this.RegisterForm.controls.password.value,
      password_confirmation: this.RegisterForm.controls.password_confirmation.value,
      role: this.RegisterForm.controls.role.value,
    }
    try {
      this.userService.Loginservice(
        environment.LoginBaseURL, `api/register`, body,
      )
        .subscribe(
          (response) => {

            debugger
            Data = response
            // if (Data.status_code == 200) {
              let tok = Data.token
                  let User = Data.user
                  localStorage.setItem("accesstoken", tok)
                  localStorage.setItem('user', JSON.stringify(User))
                  this.router.navigateByUrl('/Dashboard')
                  this._toastr.success(Data.message)
                  this.spinner.hide();
           
            // }
            // else if (Data.status_code == 204) {
            //   this._toastr.warning(Data.message)
            //   this.spinner.hide();
            // }
            
          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
           
            }
        
          
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }
  } else{
    this._toastr.warning("Password and confrim Password does not matched")
  }
  }
  else {
    
    if(this.RegisterForm.controls.name.value == ""){
      this._toastr.warning("Name must be atleast 3 characters")
    }

   else if(this.RegisterForm.controls.email.value== ""){
      this._toastr.warning("Email must be atleast 5 characters")
    }
    else if(this.RegisterForm.controls.password.value== ""){
      this._toastr.warning("Please fill a Password")
    }
    else if(this.RegisterForm.controls.password_confirmation.value== ""){
      this._toastr.warning("Please fill a confrim Password")
    }
    else if(this.RegisterForm.controls.role.value== ""){
      this._toastr.warning("Please select a role")
    }
    
  }

  }

  hidenew: boolean = true;
  new = "fa-eye-slash"
  NewPassword() {
    debugger
    this.hidenew = !this.hidenew;
    if (this.new == "fa-eye-slash") {
      this.new = "fa-eye"
    }
    else {
      this.new = "fa-eye-slash"
    }

  }

}


