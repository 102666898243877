import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppServices } from 'src/Services/App.Services';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],

})
export class DashboardComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.GetProjectList();

    let dayList: any = document.getElementById("days");
    let monthName: any = document.getElementById("month-name");
    let yearName: any = document.getElementById("year");
    let prev: any = document.getElementById("prev");
    let next: any = document.getElementById("next");
    let cale: any = document.getElementById("calendar");

    //  create date object
    let dt = new Date();
    let month = dt.getMonth() + 1;
    let year = dt.getFullYear();
    let currentDay = dt.getDate();

    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // previous button
    prev.addEventListener('click', previous);

    function previous() {
      if (month === 1) {
        month = 12;
        month -= 1;
      } else {
        month -= 1;
      }
      calendar();
    }

    // next button
    next.addEventListener('click', nextt);

    function nextt() {
      if (month === 12) {
        month = 1;
        month += 1;
      } else {
        month += 1;
      }
      calendar();
    }


    cale.addEventListener('onload', calendar());

    // make Calendar Function 
    function calendar() {

      monthName.innerHTML = monthNames[month - 1];
      yearName.innerHTML = year;
      dayList.innerHTML = "";

      let daysInMonth = new Date(year, month, 0).getDate();

      let dayNumber = new Date(year, month - 1, 1).getDay();
      let gaps;
      if (dayNumber === 0) {
        gaps = 6;
      } else {
        gaps = dayNumber - 1;
      }

      for (let day = -gaps + 1; day <= daysInMonth; day++) {
        const days: any = document.createElement("li");
        if (day <= 0) {
          days.innerHTML = "";
          dayList.appendChild(days);

        } else if (day === currentDay && month === dt.getMonth() + 1 && year === dt.getFullYear()) {
          days.setAttribute('class', 'active');
          days.innerHTML = day;
          dayList.appendChild(days);

        } else {
          days.innerHTML = day;
          dayList.appendChild(days);
        }
      }
    }
  }



ChangeMap() {
  this.router.navigateByUrl('/Map')
}

isShown: boolean = false
isShownMap: boolean = true
Client() {
  debugger
  this.router.navigateByUrl('/Client')
}

SeeMore() {
  this.router.navigateByUrl('/Project-list')
}

ProjectList = []
imageBaseUrl:any;
GetProjectList() {

  debugger

  this.spinner.show()
  let Data: any;

  try {
    this.userService.Getservice(
      environment.LoginBaseURL, `api/project/user/`+JSON.parse(localStorage.user).id
    )
      .subscribe(
        (response) => {

          debugger
          Data = response

          if(Data.status == 404){
            this.spinner.hide();
            this._toastr.warning(Data.message)
          }

          if (Data.projects.length > 0) {
            this.spinner.hide();
            this.ProjectList = Data.projects
            this.imageBaseUrl = "https://spdev.halkininc.com/laravel-api/storage/app/public/"
          }
          else {
            this.spinner.hide();
            this._toastr.warning('No Record Found!')

          }

        },
        (error) => {
          debugger
          this.spinner.hide();
          // this._toastr.warning(error.error.message)
        }
      )
  }
  catch {

    this._toastr.warning(Data.message)
    this.spinner.hide();
    debugger
  }

}

Test(obj: any){
      
  if (obj.created_at != null){
    return this.userService.dateFormat(obj.created_at)
  }   
    return obj.created_at;
}

// calen() {
//   let dayList: any = document.getElementById("days");
//   let monthName: any = document.getElementById("month-name");
//   let yearName: any = document.getElementById("year");
//   let prev: any = document.getElementById("prev");
//   let next: any = document.getElementById("next");
//   let cale: any = document.getElementById("calendar");

//   //  create date object
//   let dt = new Date();
//   let month = dt.getMonth() + 1;
//   let year = dt.getFullYear();
//   let currentDay = dt.getDate();

//   let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

//   // previous button
//   prev.addEventListener('click', previous());

//   function previous() {
//     if (month === 1) {
//       month = 12;
//       month -= 1;
//     } else {
//       month -= 1;
//     }
//     calendar();
//   }

//   // next button
//   next.addEventListener('click', nextt());

//   function nextt() {
//     if (month === 12) {
//       month = 1;
//       month += 1;
//     } else {
//       month += 1;
//     }
//     calendar();
//   }


//   cale.addEventListener('onload', calendar());

//   // make Calendar Function 
//   function calendar() {

//     monthName.innerHTML = monthNames[month - 1];
//     yearName.innerHTML = year;
//     dayList.innerHTML = "";

//     let daysInMonth = new Date(year, month, 0).getDate();

//     let dayNumber = new Date(year, month - 1, 1).getDay();
//     let gaps;
//     if (dayNumber === 0) {
//       gaps = 6;
//     } else {
//       gaps = dayNumber - 1;
//     }

//     for (let day = -gaps + 1; day <= daysInMonth; day++) {
//       const days: any = document.createElement("li");
//       if (day <= 0) {
//         days.innerHTML = "";
//         dayList.appendChild(days);

//       } else if (day === currentDay && month === dt.getMonth() + 1 && year === dt.getFullYear()) {
//         days.setAttribute('class', 'active');
//         days.innerHTML = day;
//         dayList.appendChild(days);

//       } else {
//         days.innerHTML = day;
//         dayList.appendChild(days);
//       }
//     }
//   }
// }

SignOut() {
  window.localStorage.removeItem('accesstoken');
  window.localStorage.removeItem('user');
  this.router.navigateByUrl('/login')
}
}


