<section class="vh-100">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 text-black" style=" background: aliceblue !important;">

        

        <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-5 pt-xl-0 mt-xl-n5">

          <form [formGroup]="ForgetPass" style="width: 23rem;">

            <h3 class="fw-normal mb-3 pb-3 mt-5" style="letter-spacing: 1px; font-size: 40px; "><b>Forget Password</b> </h3>

            <div class="form-outline mb-4">
              <label class="form-label" for="form2Example18">Email address</label>
              <input type="email" id="form2Example18" formControlName="email" autocomplete="off" class="form-control form-control-lg" />
             
            </div>

            <div class="form-outline mb-4">
              <label class="form-label" for="form2Example28">New Password</label>
              <input type="password" autocomplete="off" formControlName="newpass" id="form2Example28" class="form-control form-control-lg" />
           
            </div>
          

            <div class="pt-1 mb-4 ml-5"> 
              <button class="btn btn-info btn-lg btn-block" type="submit" (click)="Submit()" >Submit</button>
            </div>
           

            <!-- <p class="small pb-lg-2"><a class="text-muted" [routerLink]="['/forgot-password']" >Forgot password?</a></p> -->
            <p>Already known a Password? <a [routerLink]="['/login']" class="link-info">Login here</a></p>

          </form>

        </div>

      </div>
      <div class="col-sm-6 px-0 d-none d-sm-block">
        <!-- <img src="https://forum.huawei.com/enterprise/en/data/attachment/forum/201909/27/100129gvscd0mzd7ul9ukr.jpg?GIS.JPG" alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;"> -->
        <img src="assets/SeraPoint .png" alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">

      </div>
    </div>
  </div>
</section>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
