import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppServices } from 'src/Services/App.Services';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [ToastrService, NgxSpinnerService,]
})
export class UserComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }
  route: any;
  Heading: string = "Add User";
  is_super_admin: any;
  
  ngOnInit(): void {
    this.reactiveForm()
    this.route = history.state;
    if (this.route.val == "update") {
      this.Heading = "Update User"
      this.GetEditedData();
    }
    this.GetClient()
  }

  UsersForm: FormGroup;
  reactiveForm() {
    this.UsersForm = this.fb.group({


      fname: ['', Validators.required],
      lname: [''],
      email: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      gender: [''],
      client_id: [''],


    });
  }

  SubmitForUser() {

    debugger
    if (this.UsersForm.valid) {

      if (this.UsersForm.controls.password.value == this.UsersForm.controls.password_confirmation.value) {
        let Data: any;
        this.spinner.show();
        let body = {
          id: JSON.parse(localStorage.user).id,
          email: this.UsersForm.controls.email.value,
          fname: this.UsersForm.controls.fname.value,
          lname: this.UsersForm.controls.lname.value,
          password: this.UsersForm.controls.password.value,
          password_confirmation: this.UsersForm.controls.password_confirmation.value,
          gender: this.UsersForm.controls.gender.value,
          role: this.roleId,
          status: this.statusValue,
          clients: this.clientId,
          under_admin_id: this.UnderAdminId,
          profile_photo_path: this.ImgLabel,
          profile_photo_path_file: ""

        }
        let CheckReq
        if (this.route.val == "update") {
          CheckReq = "update"
        } else {
          CheckReq = ""
        }
        try {
          this.userService.Postservice(
            environment.LoginBaseURL, `api/add-user`, body,
          )
            .subscribe(
              (response) => {

                debugger
                Data = response
                if (Data.status == 200) {

                  this._toastr.success(Data.message)
                  this.spinner.hide();
                  
                this.UsersForm.reset();
                }
                else {
                  this._toastr.warning(Data.message)
                  this.spinner.hide();
                }

              },
              (error) => {
                debugger
                this.spinner.hide();
                this._toastr.warning(error.message)

              }


            )
        }
        catch {

          this._toastr.warning(Data.message)
          this.spinner.hide();
          debugger
        }
      } else {
        this._toastr.warning("Password and confrim Password does not matched")
      }
    }
    else {

      if (this.UsersForm.controls.fname.value == "") {
        this._toastr.warning("First Name must be atleast 3 characters")
      }

      else if (this.UsersForm.controls.lname.value == "") {
        this._toastr.warning("Last Name must be atleast 3 characters")
      }
      else if (this.UsersForm.controls.email.value == "") {
        this._toastr.warning("Email is required")
      }
      else if (this.UsersForm.controls.password.value == "") {
        this._toastr.warning("Please fill a Password")
      }
      else if (this.UsersForm.controls.password_confirmation.value == "") {
        this._toastr.warning("Please fill a confrim Password")
      }
      else if (this.UsersForm.controls.gender.value == "") {
        this._toastr.warning("Please select a gender")
      }
      else if (this.UsersForm.controls.client.value == "") {
        this._toastr.warning("Please select a client")
      }


    }

  }
  SubmitFromUserList() {

    debugger
    if (this.UsersForm.valid) {

      if (this.UsersForm.controls.password.value == this.UsersForm.controls.password_confirmation.value) {
        let Data: any;
        this.spinner.show();
        let body = {
          id: this.route.UserId,
          email: this.UsersForm.controls.email.value,
          fname: this.UsersForm.controls.fname.value,
          lname: this.UsersForm.controls.lname.value,
          password: this.UsersForm.controls.password.value,
          password_confirmation: this.UsersForm.controls.password_confirmation.value,
          gender: this.UsersForm.controls.gender.value,
          role: this.roleId,
          status: this.statusValue,
          clients: this.clientId,
          under_admin_id: this.UnderAdminId,
          profile_photo_path: this.SelectedImg
        }
        let CheckReq
        if (this.Heading == "Update User") {
          CheckReq = "users/update"
        } else {
          CheckReq = "add-user"
        }
        try {
          this.userService.Postservice(
            environment.LoginBaseURL, `api/${CheckReq}`, body,
          )
            .subscribe(
              (response) => {

                debugger
                Data = response
                if (Data.status == 200) {

                  this._toastr.success(Data.message)
                  this.reactiveForm()
                  this.ImgLabel = "Upload Image"
                  this.spinner.hide();
                  if(this.Heading == "Update User"){
                    this.router.navigateByUrl('/User-list')
                  }
                }
                else {
                  this._toastr.warning(Data.message)
                  this.spinner.hide();
                }

              },
              (error) => {
                debugger
                this.spinner.hide();
                this._toastr.warning(error.message)

              }
            )
        }
        catch {

          this._toastr.warning(Data.message)
          this.spinner.hide();
          debugger
        }
      } else {
        this._toastr.warning("Password and confrim Password does not matched")
      }
    }
    else {

      if (this.UsersForm.controls.fname.value == "") {
        this._toastr.warning("First Name must be atleast 3 characters")
      }

      else if (this.UsersForm.controls.lname.value == "") {
        this._toastr.warning("Last Name must be atleast 3 characters")
      }
      else if (this.UsersForm.controls.email.value == "") {
        this._toastr.warning("Email is required")
      }
      else if (this.UsersForm.controls.password.value == "") {
        this._toastr.warning("Please fill a Password")
      }
      else if (this.UsersForm.controls.password_confirmation.value == "") {
        this._toastr.warning("Please fill a confrim Password")
      }
      else if (this.UsersForm.controls.gender.value == "") {
        this._toastr.warning("Please select a gender")
      }
      else if (this.UsersForm.controls.client.value == "") {
        this._toastr.warning("Please select a client")
      }


    }

  }

  AddClient(){
    this.reactiveForm()
    this.Heading = "Add User"
   
  }

  ClientList = []
  GetClient() {

    debugger

    this.spinner.show()
    let Data: any;

    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/clients`
      )
        .subscribe(
          (response) => {

            debugger
            Data = response

            if (Data.clients.length > 0) {
              this.spinner.hide();
              this.ClientList = Data.clients
            }
            else {
              this.spinner.hide();
              this._toastr.warning(Data.message)
            }
          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
          }
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }
  }

  statusValue: any
  check(event: any) {
    debugger
    if (event.target.id == "Enable") {
      this.statusValue = 1
    }
    else {
      this.statusValue = 0
    }

  }

  clientId: any
  ChangeClient(event: any) {
    this.clientId = event.target.value
  }

  roleId: any
  GetRoleId(event: any) {
    debugger
    this.roleId = event.target.value
  }
  Data: any;
  GetEditedData() {

    debugger

    this.spinner.show()


    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/users/get/${this.route.UserId}`
      )
        .subscribe(
          (response) => {

            debugger
            this.Data = response
            this.PatchValues(this.Data)
            this.spinner.hide();


          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)

          }


        )
    }
    catch {

      this._toastr.warning(this.Data.message)
      this.spinner.hide();
      debugger
    }


  }
  UnderAdminId: any;
  PatchValues(key: any) {
    debugger
    this.UsersForm.patchValue({
      fname: key.user.fname,
    })
    this.UsersForm.patchValue({
      lname: key.user.lname,
    })
    this.UsersForm.patchValue({
      email: key.user.email,
    })
    this.UsersForm.patchValue({
      gender: key.user.gender,
    })
    this.UnderAdminId = key.user.under_admin_id
    this.GetClient()
    this.clientId = key.user.status
    this.is_super_admin = key.user.is_super_admin
    if (key.user.is_admin == "1") {
      this.roleId = 2
    } else {
      this.roleId = 3
    }

    if (key.user.status == "1") {
      this.statusValue = 1
    } else {
      this.statusValue = 0
    }
  }

  SelectedImg: any;
  SelectedFile: any;
  ImgLabel: string = "Upload Image"
  // processFile(imageInput:any){
  //   debugger
  //   if (imageInput.files[0]) {
  //   const file: File = imageInput.files[0];
  //   console.log(file)
  //   this.SelectedImg = file.name
  //   this.SelectedFile = file
  //   this.ImgLabel = file.name
  //   }
  // }


  processFile(event: any) {
    let file = event.target.files[0]
    this.ImgLabel = file.name
    // console.log("file", file)
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
    // console.log("uploadData", uploadData)
    let bytes
    const reader = new FileReader();
    debugger
    reader.onload = (e: any) => {
      bytes = e.target.result.split('base64,')[1];
      console.log("bytes", bytes)


      let Data: any;

      let body = {
        image: `data:${file.type};base64,${bytes}`
      }
      debugger

      console.log("image", body)

      this.SelectedImg = body.image

    };

    reader.readAsDataURL(file);

  }
}
