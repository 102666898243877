import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppServices } from 'src/Services/App.Services';

import {
  CesiumService, CircleEditUpdate, CameraService,
  CirclesEditorService,
  EllipsesEditorService,
  HippodromeEditorService,
  PolygonsEditorService,
  RangeAndBearingComponent,
  RectanglesEditorService,
  ZoomToRectangleService,
  PointsEditorService,
  PolylinesEditorService,
  MapsManagerService,
  MouseButtons,
  ViewerConfiguration,

} from 'angular-cesium';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  host: { 'window:beforeunload': 'doSomething' },
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ToastrService, NgxSpinnerService, CirclesEditorService, PointsEditorService, RectanglesEditorService, ZoomToRectangleService, EllipsesEditorService,
    PolygonsEditorService, HippodromeEditorService, CameraService, CesiumService, PolylinesEditorService, ViewerConfiguration]
})
export class LoginComponent implements OnInit {


  @ViewChild("ForgetPass", { static: true })
  ForgetPass: TemplateRef<any>


  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private fb: FormBuilder,
    private _toastr: ToastrService,
    private router: Router,

  ) { }


  ngOnInit(): void {
    this.reactiveForm()
  }

  LoginForm: FormGroup;

  reactiveForm() {
    this.LoginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],

    });
  }



  Login() {


    if(this.LoginForm.valid){
    debugger
    let Data: any;
    this.spinner.show();
    let body = {

      email: this.LoginForm.controls.email.value,
      password: this.LoginForm.controls.password.value,


    }
    try {
      this.userService.Loginservice(
        environment.LoginBaseURL, `api/login`, body,
      )
        .subscribe(
          (response) => {

            debugger
            Data = response
            if (Data.status == 200) {
              let tok = Data.token
              let User = Data.user
              localStorage.setItem("accesstoken", tok)
              localStorage.setItem('user', JSON.stringify(User))
              // this.router.navigateByUrl('/Map')
              this.router.navigateByUrl('/Dashboard')
              this._toastr.success(Data.message)
              this.spinner.hide();
            }
            else {
              this.spinner.hide();
              this._toastr.warning(Data.message)
            }

          },
          (error) => {
            debugger
          this._toastr.warning(error.error.message)
          this.spinner.hide();
            // this.ShowErrorMsg(error.error)
          }
        )
    }
    catch {

      this._toastr.error(this.userService.getSomethingWrong());
      this.spinner.hide();
      debugger
    }
  } else {
    if(this.LoginForm.controls.name.value == ""){
      this._toastr.warning("Name must be atleast 3 characters")
    }
  else if(this.LoginForm.controls.password.value == ""){
      this._toastr.warning("Password must be atleast 5 characters")
    }
  }
  }

  //  generic msg
  ShowErrorMsg(error: any) {
    let obj = error;
    if (obj != undefined && obj != null) {
      for (const key in obj) {
        this._toastr.warning(obj[key][0]);
      }
    }
    this.spinner.hide();
  }

}
