import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppServices } from 'src/Services/App.Services';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
  providers: [ToastrService, NgxSpinnerService,]
})
export class ClientComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  route: any;
  Heading:string = "Add Client";
  ngOnInit(): void {
    this.reactiveForm()
    this.route = history.state;
    if (this.route.val == "update") {
      this.Heading = "Update Client"
      this.GetEditedData();
    }    
  
  }

  UsersForm: FormGroup;
  reactiveForm() {
    this.UsersForm = this.fb.group({

      email: ['', Validators.required],
      contact_person: ['', Validators.required],
      title: ['', Validators.required],
      phone: ['', Validators.required],
      description: ['', Validators.required],
      status: [''],


    });
  }

  SubmitForClient() {

    debugger
    if (this.UsersForm.valid) {
      this.spinner.show()
      let Data: any;
      this.spinner.show();
      let body = {
        id:this.route.ClientId,
        contact_person: this.UsersForm.controls.contact_person.value,
        title: this.UsersForm.controls.title.value,
        phone: this.UsersForm.controls.phone.value,
        description: this.UsersForm.controls.description.value,
        email: this.UsersForm.controls.email.value,
        status: this.statusValue,
        client_image:this.SelectedImg
      }
      
      let CheckReq
      if( this.Heading == "Update Client"){
        CheckReq = "update"
      } else {
        CheckReq = "store"
      }
      try {
        this.userService.Postservice(
          environment.LoginBaseURL, `api/clients/${CheckReq}`, body,
        )
          .subscribe(
            (response) => {

              debugger
              Data = response
              if (Data.status == 200) {
                this.spinner.hide();
                this._toastr.success(Data.message)
                this.reactiveForm()
                this.ImgLabel = "Upload Image"
                if(this.Heading == "Update Client"){
                  this.router.navigateByUrl('/Client-list')
                }
                this.UsersForm.reset();
              }
              else {
                this.spinner.hide();
                this._toastr.warning(Data.message)

              }

            },
            (error) => {
              debugger
              this.spinner.hide();
              this._toastr.warning(error.error.message)

            }


          )
      }
      catch {

        this._toastr.warning(Data.message)
        this.spinner.hide();
        debugger
      }

    }
    else {

      if (this.UsersForm.controls.title.value == "") {
        this._toastr.warning("Title must be atleast 3 characters")
      }

      else if (this.UsersForm.controls.contact_person.value == "") {
        this._toastr.warning("Contact person must be atleast 3 characters")
      }
      else if (this.UsersForm.controls.phone.value == "") {
        this._toastr.warning("Phone number must be atleast 8 digit")
      }
      else if (this.UsersForm.controls.description.value == "") {
        this._toastr.warning("description must be atleast 5 characters")
      }
      else if (this.UsersForm.controls.email.value == "") {
        this._toastr.warning("Email is required")
      }

    }

  }
  AddClient(){
    this.reactiveForm()
    this.Heading = "Add Client"
   
  }

  statusValue: any
  check(event: any) {
    debugger
    if (event.target.id == "Enable") {
      this.statusValue = 1
    }
    else {
      this.statusValue = 0
    }

  }

  Data: any;
  GetEditedData() {

    debugger

    this.spinner.show()


    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/clients/get/${this.route.ClientId}`
      )
        .subscribe(
          (response) => {

            debugger
            this.Data = response
            this.PatchValues(this.Data)
            this.spinner.hide();


          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)

          }


        )
    }
    catch {

      this._toastr.warning(this.Data.message)
      this.spinner.hide();
      debugger
    }


  }

  PatchValues(key: any) {
    this.UsersForm.patchValue({
      title: key.client.title,
    })
    this.UsersForm.patchValue({
      contact_person: key.client.contact_person,
    })
    this.UsersForm.patchValue({
      email: key.client.email,
    })
    this.UsersForm.patchValue({
      description: key.client.description,
    })

    this.UsersForm.patchValue({
      phone: key.client.phone,
    })

  }

  SelectedImg:any;
  SelectedImgFile:any;
  ImgLabel: string = "Upload Image"
  // processFile(imageInput:any){
  //   debugger
  //   if (imageInput.files[0]) {
  //   const file: File = imageInput.files[0];
  //   console.log(file)
  //   this.SelectedImg = file.name
  //   this.SelectedImgFile = file
  //   this.ImgLabel = file.name
  //   }
  // }

  processFile(event:any) {
    let file = event.target.files[0]
    this.ImgLabel = file.name
    // console.log("file", file)
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
    // console.log("uploadData", uploadData)
    let bytes
    const reader = new FileReader();
    debugger
    reader.onload = (e: any) => {
      bytes = e.target.result.split('base64,')[1];
       console.log("bytes", bytes)

      
      let Data: any;

      let body = {
        image: `data:${file.type};base64,${bytes}`
      }
      debugger

       console.log("image", body)

      this.SelectedImg  = body.image

    };

    reader.readAsDataURL(file);

  }
}
