import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './authguard/auth.guard';

import { DashboardModule } from 'src/app/Forms/dashboard/dashboard.module';
import { AngularCesiumModule, AngularCesiumWidgetsModule } from 'angular-cesium';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },

      {
        path: 'login',
        component: LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgetpasswordComponent,
      },
    ],
  },
];


@NgModule({
  declarations: [

    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ForgetpasswordComponent
  ],
  imports: [
    CommonModule,
     NgxSpinnerModule,
    ReactiveFormsModule,
    // NgModule,
    ToastrModule,
    DashboardModule,
    RouterModule.forChild(routes),
    // AngularCesiumModule.forRoot(),
    // AngularCesiumWidgetsModule,
  ],
  exports: [AuthComponent],

})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthGuard],
    };
  }
}
