
<div class="map">
    <div class="map-container">
        <div class="iframe">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196281.12937505348!2d-104.99519351719373!3d39.76451867179533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sDenver%2C%20CO%2C%20USA!5e0!3m2!1sen!2s!4v1648154577065!5m2!1sen!2s"></iframe>"
        </div>
        <div class="calendar" id="calendar">
            <div class="month" id="month">
                <ul>
                    <li class="prev" id="prev">&#10094;</li>
                    <li class="next" id="next">&#10095;</li>
                    <li>
                        <span class="month-name" id="month-name"></span><span class="year" id="year"></span>
                    </li>
                </ul>
            </div>

            <ul class="weekdays">
                <li>Mo</li>
                <li>Tu</li>
                <li>We</li>
                <li>Th</li>
                <li>Fr</li>
                <li>Sa</li>
                <li>Su</li>
            </ul>

            <ul class="days" id="days">  
              </ul>
        </div>
    </div>
    <div class="map-project">
        <div class="proj" *ngFor="let Projects of ProjectList | slice:0:9;">
            <div class="proj-name">{{Projects.title}}</div>
           <!--  <div class="proj-name">
            Client(s): <span *ngIf="Projects.ctitle != '' || Projects.ctitle != null">{{Projects.ctitle}}</span>
            <span *ngFor="let Clients of Projects.clients">{{Clients.title}}, </span> 
        </div>-->
            <div class="proj-data">
                <div class="proj-date"><span class="green">Date: {{Test(Projects)}}</span>
                    <div *ngIf="Projects.status == 1"> Assign By: Super Admin</div>
                    <div *ngIf="Projects.status == 2">Assign By: Admin</div>
                    <div *ngIf="Projects.status == 2">Assign By: User</div>
                </div>
                <div class="proj-pic">
                  <img *ngIf="Projects.image != null;  else elseImg" src={{imageBaseUrl}}{{Projects.image}}>
                  <ng-template #elseImg>
                    <img src="assets/Icons/icon/project-ph.png" alt="addProject">
                </ng-template>
                   
                </div>
            </div>
        </div>


    </div>
    <div class="see-more">
        <button (click)="SeeMore()">See More</button>
    </div>

</div>



<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>