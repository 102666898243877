

<div id="clientList-box">
    <div class="clientList">
        <div class="cl-header">
            <div class="cl-text">Client List</div>
        </div>
        <div class="cl-container">
            <div class="container" *ngFor="let Client of clientsList">
                <div class="container-info">
                    <span>Name: {{Client.contact_person}}</span>
                    <span>Company: {{Client.title}}</span>
                    <span>Email: {{Client.email}}</span>
                    <img (click)="updateClientList($event)" id="{{Client.id}}" src="assets/Icons/icon/edit.png"
                        alt="" height="25px" width="25px" class="edit-btn">
                </div>
                <div class="container-img">
                    <img *ngIf="Client.client_image != null;  else elseImg" src={{imageBaseUrl}}{{Client.client_image}}>
                    <ng-template #elseImg>
                        <img src="assets/Icons/icon/placeholder.jpg" alt="addClient">
                  </ng-template>
                  
                </div>
            </div>
        </div>
    </div>
</div>




<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>