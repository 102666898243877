<div class="annotation" id="annotation">
    <div class="anno-container">
        <div class="anno-header">
            <div class="anno-name">Annotation List</div>
        </div>
        <div class="anno-body">
            <div class="selects">
                <div class="record">
                    <label for="record">Record</label>
                    <select name="record" id="record">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div class="search">
                    <label for="" class="search">Search</label>
                    <input type="search" name="" id="" placeholder="Search here" >
                </div>
            </div>
            <table id="annotation-table">
                <thead>
                    <tr>
                        <th><input type="checkbox" id="select-all-anno" (click)="selectAll()">Select</th>
                        <th>Project Name</th>
                        <th>Annotation Title</th>
                        <th>User</th>
                        <th>Created date</th>
                        <th>Attachment</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <div class="tbody">
                        <tr>
                            <td><input type="checkbox" name="chk" class="chk"></td>
                            <td>P.Name</td>
                            <td>Title</td>
                            <td>User Name</td>
                            <td>00-00-0000</td>
                            <td>
                                <img src="../../../assets/Icons/icon/attach.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/edit.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/delete.png" alt="" height="25px" width="25px">
                            </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox" name="chk" class="chk"></td>
                            <td>P.Name</td>
                            <td>Title</td>
                            <td>User Name</td>
                            <td>00-00-0000</td>
                            <td>
                                <img src="../../../assets/Icons/icon/attach.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/edit.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/delete.png" alt="" height="25px" width="25px">
                            </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox" name="chk" class="chk"></td>
                            <td>P.Name</td>
                            <td>Title</td>
                            <td>User Name</td>
                            <td>00-00-0000</td>
                            <td>
                                <img src="../../../assets/Icons/icon/attach.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/edit.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/delete.png" alt="" height="25px" width="25px">
                            </td>
                        </tr>
                        <tr>
                            <td><input type="checkbox" name="chk" class="chk"></td>
                            <td>P.Name</td>
                            <td>Title</td>
                            <td>User Name</td>
                            <td>00-00-0000</td>
                            <td>
                                <img src="../../../assets/Icons/icon/attach.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/edit.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img src="../../../assets/Icons/icon/delete.png" alt="" height="25px" width="25px">
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
            <div class="select-all" id="delete-all-anno">
                <div class="del">
                    <img src="../../../assets/Icons/icon/deleteTable.png" alt="" height="25px" width="25px"><label
                        for="">Delete
                        Selected</label>
                </div>
                <!-- <div class="pagination" id="anno-pagination">
                    <a href="">&laquo;</a>
                    <a href="" class="active">1</a>
                    <a href="">&raquo;</a>
                </div> -->
            </div>
        </div>
    </div>
</div>