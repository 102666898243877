import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnotationsComponent } from 'src/app/Forms/annotations/annotations.component';
import { ClientListComponent } from 'src/app/Forms/client-list/client-list.component';
import { ClientComponent } from 'src/app/Forms/client/client.component';
import { DashboardComponent } from 'src/app/Forms/dashboard/dashboard.component';
import { ProjectListComponent } from 'src/app/Forms/project-list/project-list.component';
import { ProjectComponent } from 'src/app/Forms/project/project.component';
import { TestComponent } from 'src/app/Forms/test/test.component';
import { UserListComponent } from 'src/app/Forms/user-list/user-list.component';
import { UserComponent } from 'src/app/Forms/user/user.component';
import { AuthGuard } from 'src/Global/auth/authguard/auth.guard';
import { Basecomponent } from 'src/Global/basecomponent/basecomponent.component';
// import { DashboradComponent } from 'src/app/Forms/dashboard/dashboard.component';




const routes: Routes = [
  {
		path: "",
		loadChildren: () =>
			import("../Global/auth/auth.module").then(m => m.AuthModule)
	},
  {
		path: "",
		component: Basecomponent,
	//	canActivate: [AuthGuard],
		children: [
			{
				path: "Dashboard",canActivate: [AuthGuard], component: DashboardComponent,
				loadChildren: () =>
					import("../app/Forms/dashboard/dashboard.module").then(
						m => m.DashboardModule
					)
			},
			{
				path: "Map", canActivate: [AuthGuard], component: TestComponent,
				loadChildren: () =>
					import("../app/Forms/test/test.module").then(
						m => m.TestModule
					)
			},
			{
				path: "Client", canActivate: [AuthGuard], component: ClientComponent,
				loadChildren: () =>
					import("../app/Forms/client/client.module").then(
						m => m.ClientModule
					)
			},
			{
				path: "Client-list", canActivate: [AuthGuard], component: ClientListComponent,
				loadChildren: () =>
					import("../app/Forms/client-list/client-list.module").then(
						m => m.ClientListModule
					)
			},
			{
				path: "User", canActivate: [AuthGuard], component: UserComponent,
				loadChildren: () =>
					import("../app/Forms/user/user.module").then(
						m => m.UserModule
					)
			},
			{
				path: "User-list", canActivate: [AuthGuard], component: UserListComponent,
				loadChildren: () =>
					import("../app/Forms/user-list/user-list.module").then(
						m => m.UserListModule
					)
			},
			{
				path: "Project", canActivate: [AuthGuard], component: ProjectComponent,
				loadChildren: () =>
					import("../app/Forms/project/project.module").then(
						m => m.ProjectModule
					)
			},
			{
				path: "Project-list", canActivate: [AuthGuard], component: ProjectListComponent,
				loadChildren: () =>
					import("../app/Forms/project-list/project-list.module").then(
						m => m.ProjectListModule
					)
			},
			{
				path: "Annotations", canActivate: [AuthGuard], component: AnnotationsComponent,
				loadChildren: () =>
					import("../app/Forms/annotations/annotations.module").then(
						m => m.AnnotationsModule
					)
			},
	
		
			// { path: "", redirectTo: "dashboard", pathMatch: "full" },
			// { path: "**", redirectTo: "dashboard", pathMatch: "full" }

		]
	},

  { path: "**", redirectTo: "error/403", pathMatch: "full" }
];

@NgModule({
 imports: [RouterModule.forRoot(routes, {useHash:true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
