import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.css']
})
export class AnnotationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  selectAll() {

    var btnChecked: any = document.getElementById("select-all-anno");
    var btn: any = btnChecked.checked;
    var chk: any = document.getElementsByName("chk");
    var intLength = chk.length;
    for (var i = 0; i < intLength; i++) {
      var check = chk[i];
      check.checked = btn;
    }

    var tableRef: any = document.getElementById('annotation-table');
    var tableRows = tableRef.rows;

    var deleteAll: any = document.getElementById("delete-all-anno");
    deleteAll.addEventListener('click', function () {
      var checkedIndexes = [];
      for (var i = 0; i < tableRows.length; i++) {
        var checkboxSelected = tableRows[i].cells[0].children[0].checked;
        if (checkboxSelected) {
          checkedIndexes.push(i);
        }
      }

      for (var k = checkedIndexes.length - 1; k >= 1; k--) {
        tableRef.deleteRow(checkedIndexes[k]);
      }
    });

  }

 
}
