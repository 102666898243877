import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basecomponent',
  templateUrl: './basecomponent.component.html',
  styleUrls: ['./basecomponent.component.css']
})
export class Basecomponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  Profile: any;
  UserName: any;
  superAdmin: any;
  admin: any;
  userType: any
  mapPage: boolean = true
  IsMapShow: boolean = false
  imageBaseUrl:any;
  ngOnInit(): void {
    this.imageBaseUrl = "https://spdev.halkininc.com/laravel-api/storage/app/public/"
    this.Profile = JSON.parse(localStorage.user).profile_photo_path
    this.UserName = JSON.parse(localStorage.user).fname
    this.superAdmin = JSON.parse(localStorage.user).is_super_admin
    this.admin = JSON.parse(localStorage.user).is_admin
    if(this.admin == 1 || this.superAdmin == 1){
      this.userType = 'Admin';
    }else{
      this.userType = 'User';
    }
    
  }

  ChangeMap() {

    this.router.navigateByUrl('/Map')
    // this.mapPage = false
    // this.IsMapShow = true
  }

  isShown: boolean = false
  isShownMap: boolean = true
  Client() {
    // this.router.navigateByUrl('/Client');
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/Client']));
  }

  ClientList() {
    debugger
    this.router.navigateByUrl('/Client-list');

  }

  Users() {
    debugger
    // this.router.navigateByUrl('/User');
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/User']));

  }

  UsersList() {
    this.router.navigateByUrl('/User-list');

  }
  Project(){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/Project']));
 }
  // Project() {
  //   debugger
  //   this.router.navigateByUrl('/Project');

  // }

  ProjectList() {
    this.router.navigateByUrl('/Project-list');

  }
  Annotations() {
    this.router.navigateByUrl('/Annotations')

  }
  Home() {
    this.router.navigateByUrl('/Dashboard')
  }

  User_id:any;
  UpdateUser(){
    this.router.navigateByUrl('/User',{ state: { val: "update",UserId: JSON.parse(localStorage.user).id} })
  }

  CallMap() {
    debugger
const mapForm = document.createElement('form');
mapForm.method = 'GET';
mapForm.action = `https://spdev.halkininc.com/Map/`;
mapForm.style.display = 'none';

const mapInput = document.createElement('input');
mapInput.type = 'hidden';
mapInput.name = 'user_id';
mapInput.value = JSON.parse(localStorage.user).id;
mapForm.appendChild(mapInput);

const mapInput1 = document.createElement('input');
mapInput1.type = 'hidden';
mapInput1.name = 'token';
mapInput1.value = localStorage.accesstoken;
mapForm.appendChild(mapInput1);

document.body.appendChild(mapForm);
  mapForm.submit(); 
  }

  // logoutBtn() {
  //   let logout: any = document.getElementById("logout");
  //   if (logout.style.display == "none") {
  //     logout.style.display = "block";
  //   } else {
  //     logout.style.display = "none";
  //   }
  // }

  menuHide() {

    let colLg10: any = document.getElementById("col-lg-10");
    colLg10.style.width = 'calc(100% - 60px)';
    colLg10.style.marginLeft = '60px';

    let mIcon: any = document.getElementById("menu-icon");
    mIcon.style.display = 'none';

    let mIcon1: any = document.getElementById("menu-icon1");
    mIcon1.style.display = 'block';

    // header width
    let header: any = document.getElementById("header");
    header.style.left = "60px";

    // header menu
    let main: any = document.getElementById("main-menu");
    main.style.width = "calc(100% - 60px)";

    // hide logo/Name in sidebar 
    var logoName: any = document.getElementById("logo-name");
    logoName.style.display = "none";

    // width of sidebar
    let menu: any = document.getElementById("sidebar");
    menu.style.width = "60px";

    // hide names in sidebar 
    var bName: any = document.getElementsByClassName("button-name");
    for (var i = 0, len = bName.length; i < len; i++) {
      bName[i].style.display = 'none';
    }

    //  hide arrows in sidebar
    var arrows: any = document.getElementsByClassName("down-arrow");
    for (var i = 0, len = arrows.length; i < len; i++) {
      arrows[i].style.display = 'none';
    }

      // change submenu subbar
    let click: any = document.getElementsByClassName('sub-menu');

    for (var i = 0, len = click.length; i < len; i++) {
      click[i].childNodes[0].style.height = '30px';
      click[i].childNodes[1].style.height = '30px';
    }

    let maintxt: any = document.getElementsByClassName('menu-name-label');
    for (var i = 0, len = maintxt.length; i < len; i++) {
      maintxt[i].style.visibility = 'visible';
    }
  
    let more: any = document.getElementsByClassName('more-icon');
    let hideTxt: any = document.querySelectorAll('.hide-txt');

    for (var i = 0, len = more.length; i < len; i++) {
      more[i].style.display = 'block';
      more[i].style.marginLeft = '2px';
      hideTxt[i].style.display = 'none';
    }


    //logout text hide
    // let logout: any = document.getElementById("logout");
    // logout.style.display = 'none';

  }

  menuShow() {

    let colLg10: any = document.getElementById("col-lg-10");
    colLg10.style.width = "calc(100% - 200px)";
    colLg10.style.marginLeft = '200px';

    //header width
    let header: any = document.getElementById("header");
    header.style.left = "200px";

    // header menu
    let main: any = document.getElementById("main-menu");
    main.style.width = 'calc(100% - 200px)';

    //menu Icons
    let mIcon: any = document.getElementById("menu-icon");
    mIcon.style.display = 'block';
    let mIcon1: any = document.getElementById("menu-icon1");
    mIcon1.style.display = 'none';

    // show logo/Name in sidebar 
    var logoName: any = document.getElementById("logo-name");
    logoName.style.display = "block";

    // width of sidebar
    let menu: any = document.getElementById("sidebar");
    menu.style.width = "200px";

    // show names in sidebar 
    var bName: any = document.getElementsByClassName("button-name");
    for (var i = 0, len = bName.length; i < len; i++) {
      bName[i].style.display = 'inline';
    }
    //  show arrows in sidebar
    var arrows: any = document.getElementsByClassName("down-arrow");
    for (var i = 0, len = arrows.length; i < len; i++) {
      arrows[i].style.display = 'inline';
    }

    // change submenu subbar
    let click: any = document.getElementsByClassName('sub-menu');

    for (var i = 0, len = click.length; i < len; i++) {
      click[i].childNodes[0].style.height = '50px';
      click[i].childNodes[1].style.height = '50px';
    }

    let more: any = document.getElementsByClassName('more-icon');
    let hideTxt: any = document.querySelectorAll('.hide-txt');
    for (var i = 0, len = more.length; i < len; i++) {
      more[i].style.display = 'none';
      more[i].style.marginLeft = '0';
      hideTxt[i].style.display = 'block';
    }

    let maintxt: any = document.getElementsByClassName('menu-name-label');
    for (var i = 0, len = maintxt.length; i < len; i++) {
      maintxt[i].style.visibility = 'hidden';
    }

    // //logout Text hide
    // let logout: any = document.getElementById("logout");
    // logout.style.display = 'none';
  }


  SignOut() {
    window.localStorage.removeItem('accesstoken');
    window.localStorage.removeItem('user');
    this.router.navigateByUrl('/login');
  }
}





