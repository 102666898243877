import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppServices } from 'src/Services/App.Services';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
  this.GetClientList()
  }
  checkUser:any;
  UserList = []
  imageBaseUrl:any;
  GetClientList() {

    debugger

    this.spinner.show()
    let Data: any;

    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/users/get_list/`+JSON.parse(localStorage.user).id
      )
        .subscribe(
          (response) => {

            debugger
            Data = response

            if (Data.users.length > 0) {
              this.spinner.hide();
              this.UserList = Data.users
              debugger
              this.imageBaseUrl = "https://spdev.halkininc.com/laravel-api/storage/app/public/"
            }
            else {
              this.spinner.hide();
              this._toastr.warning('No Record Found!')
            }

          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
          }
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }

  }

  User_id:any;
  updateUserList(event:any){
    debugger
    this.User_id = event.target.id
    this.router.navigateByUrl('/User',{ state: { val: "update",UserId:this.User_id } })
  }
}
