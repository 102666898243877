<div id="addUser-box">
    <div class="content">
        <div class="content-header">
            <div class="form-name">{{Heading}}</div>
        </div>
        <div class="content-body">
            <form [formGroup]="UsersForm" autocomplete="off" enctype="multipart/form-data">
                <table>
                    <tr>
                        <td>
                            <label for="">First Name</label>
                        </td>
                        <td>
                            <input type="text" formControlName="fname" placeholder="First Name" autocomplete="new-fname">
                        </td>
                        <td>
                            <label for="">Last Name</label>

                        </td>
                        <td>
                            <input type="text" formControlName="lname" placeholder="Last Name" autocomplete="new-lname" >
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="">Password</label>

                        </td>
                        <td>
                            <input type="password" formControlName="password" placeholder="Password" autocomplete="new-password">
                        </td>
                        <td>
                            <label for="">Email</label>
                        </td>
                        <td>
                            <input type="email" formControlName="email" placeholder="Email Address" autocomplete="new-email">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="">Re-type Passowrd</label>
                        </td>
                        <td>
                            <input type="password" formControlName="password_confirmation"
                                placeholder="Re-type Passowrd" autocomplete="new-password_confirmation">
                        </td>
                        <td>
                            <label for="">Gender</label>
                        </td>
                        <td>
                            <select name="gender" id="gender" formControlName="gender">
                                <option value="">Select Gender</option>
                                <option value="1">Male</option>
                                <option value="0">Female</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="is_super_admin != 1">
                            <label for="">Client</label>
                        </td>
                        <td *ngIf="is_super_admin != 1">
                            <select formControlName="client_id" placeholder="Select Client" (change)="ChangeClient($event)">
                                <option value="">Select Client</option>
                                <option *ngFor="let ClientDrop of ClientList" [value]="ClientDrop.id">
                                    {{ClientDrop.title}}</option>

                            </select>
                        </td>
                        <td *ngIf="is_super_admin != 1">
                            <label for="">Role</label>
                        </td>
                        <td *ngIf="is_super_admin != 1">
                            <select (change)="GetRoleId($event)">
                                <option>Select Role</option>
                                <option value="2">Admin</option>
                                <option value="3">User</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="">Status</label>
                        </td>
                        <td (change)="check($event)">
                            <input type="radio" name="status" id="Enable"><label for="">Enable</label>
                            <input type="radio" name="status" id="Disable"><label for="">Disable</label>
                        </td>
                        <td>File Upload</td>
                        <td>
                            <label for="file-upload" class="custom-file-upload">{{ImgLabel}}</label>
                            <input #imageInput accept="image/*" (change)="processFile($event)" id="file-upload"
                                type="file" />
                        </td>
                    </tr>
                </table>

                <!-- <div> -->

                    <!-- <div class="full">
                        <label for="" class="file-up">File Upload</label>
                        <label for="file-upload" class="custom-file-upload">{{ImgLabel}}</label>
                        <input #imageInput accept="image/*" (change)="processFile(imageInput)" id="file-upload"
                            type="file" />
                    </div> -->
                <!-- </div> -->
                <!-- <div *ngIf="this.Heading == 'Update User' ">
                    <button type="button" (click)="AddClient()">Add Client</button>
                </div> -->
                <div class="submit">
                    <!-- <div *ngIf="this.Heading == 'Update User' ">
                        <button type="button" (click)="AddClient()">Add User</button>
                    </div> -->
                    <button type="submit" (click)="SubmitFromUserList()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>