import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  CesiumService, CircleEditUpdate, CameraService,
  CirclesEditorService,
  EllipsesEditorService,
  HippodromeEditorService,
  PolygonsEditorService,
  RangeAndBearingComponent,
  RectanglesEditorService,
  ZoomToRectangleService,
  PointsEditorService,
  PolylinesEditorService,
  MapsManagerService,
  MouseButtons,
  ViewerConfiguration,
  AcNotification,
  ActionType,
} from 'angular-cesium';
import { CircleEditorObservable } from 'angular-cesium/lib/angular-cesium-widgets/models/circle-editor-observable.d';
import { PolygonEditorObservable } from 'angular-cesium/lib/angular-cesium-widgets/models/polygon-editor-observable.d';
import { PolylineEditorObservable } from 'angular-cesium/lib/angular-cesium-widgets/models/polyline-editor-observable.d';
import { PointEditorObservable } from 'angular-cesium/lib/angular-cesium-widgets/models/point-editor-observable.d';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettingsService } from './app-settings-service';
import { MockDataService } from './MockDataService';
import { MockData3dService } from './MocData3dTilesService';
// import { CirclesEditorService } from 'angular-cesium/lib/angular-cesium-widgets/services/entity-editors/circles-editor/circles-editor.service.d';
// import { CameraService } from 'angular-cesium/lib/angular-cesium/services/camera/camera.service';
// import { CesiumService } from 'angular-cesium/lib/angular-cesium/services/cesium/cesium.service';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
  providers: [AppSettingsService, CirclesEditorService, PointsEditorService, RectanglesEditorService, ZoomToRectangleService, EllipsesEditorService,
    PolygonsEditorService, HippodromeEditorService, CameraService, CesiumService, PolylinesEditorService, ViewerConfiguration]
})
export class TestComponent implements OnInit {

  billboards$: Observable<AcNotification>;
  editing$: PolylineEditorObservable;
  pointEditor$: PointEditorObservable;
  Editing$: CircleEditorObservable;
  rnb: PolygonEditorObservable;
  @ViewChild('rangeAndBearing') private rangeAndBearing: RangeAndBearingComponent;

  constructor(
    private CircleEditor: CirclesEditorService,
    private rectanglesEditor: RectanglesEditorService,
    private zoomToRectangleService: ZoomToRectangleService,
    private ellipsesEditor: EllipsesEditorService,
    private polygonsEditor: PolygonsEditorService,
    private hippodromesEditor: HippodromeEditorService,
    private polylineEditor: PolylinesEditorService,
    private cameraService: CameraService,
    private cesiumService: CesiumService,
    private MapManager: MapsManagerService,
    private viewConfig: ViewerConfiguration,
    private pointEditor: PointsEditorService,
    private mockDataProvider: MockDataService,
    private get3dTiles: MockData3dService,
    private router: Router,
    public appSettingsService: AppSettingsService,
    private viewerConf: ViewerConfiguration
  ) {
    viewerConf.viewerOptions = {
      selectionIndicator: false,
      timeline: false,
      infoBox: false,
      fullscreenButton: false,
      baseLayerPicker: false,
      animation: false,
      shouldAnimate: false,
      homeButton: false,
      geocoder: true,
      navigationHelpButton: false,
      navigationInstructionsInitiallyVisible: false,
      mapMode2D: Cesium.MapMode2D.ROTATE,
    };

    viewerConf.viewerModifier = (viewer: any) => {
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    };

    this.appSettingsService.showTracksLayer = true;
  }

  name = 'Angular';
  cesiumLoaded = true;
  Cesium = Cesium;

// 3D tiles
  entities$: Observable<AcNotification>;
  ngOnInit(): void {
    // const data = this.mockDataProvider.getDataSteam$().pipe(map(entity => ({
    //   id: entity.id,
    //   actionType: ActionType.ADD_UPDATE,
    //   entity: entity,
    // })));
    // debugger;
    // console.log("writting data");
    // console.log(data);
    // this.billboards$ = data;

    // 3d tiles
debugger
    const Tiledata = this.get3dTiles.getDataSteam$().pipe(map(entity => ({
      id: entity.id,
      actionType: ActionType.ADD_UPDATE,
      entity: entity,
    })));
    console.log({Tiledata});
    this.entities$ = Tiledata;
    

  }
  drawCircle() {
    debugger
    this.CircleEditor.create();
  }

  //ractangle
  drawRectangle() {
    this.rectanglesEditor.create();
  }

  // circle
  //  drawCircle() {
  //   debugger
  //   this.CircleEditor.create();

  //    const initialPos = [
  //        Cesium.Cartesian3.fromDegrees(0, 0),
  //        Cesium.Cartesian3.fromDegrees(0, 0),
  //        Cesium.Cartesian3.fromDegrees(0, 0)
  //    ];
  //    let Coordinates = Cesium.Cartesian3.fromDegrees(0, 0)
  //    this.Editing$ = this.CircleEditor.edit(Coordinates,0);

  // }

  // create RangeAnd Bearing
  createRangeAndBearing() {
    if (this.rnb) {
      this.rnb.dispose();
    }

    this.rnb = this.rangeAndBearing.create();
  }


  zoomOptions = {
    animationDurationInSeconds: 0.5,
    resetKeyCode: 27,
    borderStyle: '2px solid rgba(0,0,0,0.5)',
    backgroundColor: 'rgba(0,0,0,0.2)',
    autoDisableOnZoom: true, threshold: 9,
    keepRotation: true,
    mouseButton: MouseButtons.LEFT,
  }
  zoomToRectangle() {
    const currentMap = this.MapManager.getMap();
    if (currentMap) {
      this.cesiumService.init(currentMap.getMapContainer(), currentMap);
      this.zoomToRectangleService.init(this.cesiumService, this.cameraService);
      this.zoomToRectangleService.activate(this.zoomOptions, currentMap.mapId);
    }
  }

  // zoomToRectangle() {
  //   debugger
  // // let options =  { animationDurationInSeconds: 0.5, 
  // //     resetKeyCode: 27, 
  // //     borderStyle: '2px solid rgba(0,0,0,0.5)', 
  // //     backgroundColor: 'rgba(0,0,0,0.2)', 
  // //     autoDisableOnZoom: true, threshold: 9, 
  // //     keepRotation: true, 
  // //     mouseButton: MouseButtons.RIGHT,
  // //     }
  // //     let view = this.cesiumService.getViewer()
  // //     console.log(view)

  //   this.zoomToRectangleService.activate({onComplete: () => this.zoomToRectangleService.activate()})

  //   // this.saqib = this.cesiumService.getMap()
  //   // console.log(this.saqib)
  //   // this.Ateeb = this.MapManager.getMap()
  //   // console.log(this.Ateeb)

  // }


  // draw Ellipse
  drawEllipse() {
    this.ellipsesEditor.create();
  }


  // draw Polygon
  drawPolygon() {
    this.polygonsEditor.create();
  }


  // draw Hippodrome
  drawHippodrome() {
    debugger
    this.hippodromesEditor.create({
      hippodromeProps: {
        outline: true,
        outlineWidth: 2,
        width: 20
      },
    });
  }

  startEdit() {
    debugger
    if (this.Editing$) {
      this.stopEdit();
    }
    this.Editing$ = this.CircleEditor.create();
    this.Editing$.subscribe((editUpdate: CircleEditUpdate) => {
      // current edit value
      console.log(editUpdate);
      // or
      console.log('center', this.Editing$.getCenter());
      console.log('radius', this.Editing$.getRadius());
    });
  }

  stopEdit() {
    if (this.Editing$) {
      this.Editing$.dispose();
      //  this.editing$ = 0;
    }
  }
  // point tool 
  startPoint() {
    this.pointEditor$ = this.pointEditor.create();
    
    // Or Edit from existing points  
    const initialPos = Cesium.Cartesian3.fromDegrees(45, 40);
    this.pointEditor$ = this.pointEditor.edit(initialPos);
}

  startDraw() {
    this.editing$ = this.polylineEditor.create();

    // Or Edit from existing points  
    const initialPos = [
      Cesium.Cartesian3.fromDegrees(20, 40),
      Cesium.Cartesian3.fromDegrees(45, 40),
      Cesium.Cartesian3.fromDegrees(30, 20)
    ];
    this.editing$ = this.polylineEditor.edit(initialPos);
  };

  SignOut() {
    window.localStorage.removeItem('accesstoken');
    this.router.navigateByUrl('/login')
  }

  drawpolyLine() {
    debugger
    this.polylineEditor.create();
  }

coolapsed:boolean = false

ToggleHandler(){
this.coolapsed = !this.coolapsed
}

isOpened:boolean
IsShow =""
hover(event:any){
  if(event == "show"){
 this.IsShow = "show"
  } else {
    this.IsShow = ""
  }
}
IsShowView =""
Viewhover(event:any){
  if(event == "show"){
 this.IsShowView = "show"
  } else {
    this.IsShowView = ""
  }
}

IsShowViewNavigate =""
Navigatehover(event:any){
  if(event == "show"){
 this.IsShowViewNavigate = "show"
  } else {
    this.IsShowViewNavigate = ""
  }
}
// HoverOnList(){
//   debugger
//   if(this.Show == "show"){
//     this.Show = ""
//   } else {
//     this.Show = "show"
//   }
// }

 

}
