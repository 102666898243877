<div class="rows" id="row">
    <header id="header">
        <div class="main-menu" id="main-menu">
            <div (click)="menuHide()" class="menu-icon" id="menu-icon">
                <img src="assets/Icons/icon/menu.png" alt="" height="20px" width="20px">
            </div>
            <div (click)="menuShow()" class="menu-icon1" id="menu-icon1">
                <img src="assets/Icons/icon/menu.png" alt="" height="20px" width="20px">
            </div>
            <div class="menu-profile">
                <img  *ngIf="Profile != null;  else elseImg" src={{imageBaseUrl}}{{Profile}} class="profile-image"  (click)="UpdateUser()"
                    alt="profile" height="38px" width="38px">
                <ng-template #elseImg>
                    <img src="assets/Icons/icon/placeholder.jpg" alt=""height="38px" width="38px">
                </ng-template>
                <span class="profile-text">{{UserName}}
                    <img src="assets/Icons/icon/sortDown.png" alt="" height="18px" width="18px">
                    <div class="logout-cl">
                        <a id="logout" class="logout" (click)="SignOut()" href="">Logout</a>
                    </div>
                </span>
            

            <!-- (click)="logoutBtn()" -->
        </div>
</div>
</header>
<div class="col-lg-2" id="col-lg-2">
    <div class="sidebar" id="sidebar">
        <div class="sidebar-header">
            <a href="https://serapoint.com" id="logo-name">
                <img src="assets/MainHome.png" alt="" height="100%" width="100%">
            </a>
        </div>
        <div class="sidebar-body">


            <a (click)="Home()" id="home" class="active">
                <span class="icons-back">
                    <img src="assets/Icons/icon/home.png" alt="" height="25px" width="25px"><span
                        class="menu-name-label">Home</span></span>
                <span class="button-name">Home </span>
            </a>
            <a (click)="CallMap()">
                <span class="icons-back">
                    <img src="assets/map.png" alt="" height="25px" width="25px">
                    <span class="menu-name-label">Map</span>
                </span>
                <span class="button-name">Map </span>
            </a>
            <!--  -->
            <a id="clients">
                <span class="icons-back">
                    <img src="assets/Icons/icon/clients.png" alt="" height="25px" width="25px">
                    <span class="menu-name-label">Clients</span>
                </span>
                <span class="button-name">Clients</span>
                <span class="down-arrow">
                    <img src="assets/Icons/icon/downArrow.png" alt="" height="10px" width="10px">
                </span>
                <div class="sub-menu" id="c-submenu">
                    <span *ngIf="userType === 'Admin' || userType === 'User'">
                    <a id="addClient" (click)="Client()">
                        <span class="hide-txt">Add Client</span>
                        <img class="more-icon" src="assets/Icons/icon/addClient.png" alt="" height="25px" width="25px">
                        <span class="name-label">Add Client</span>
                    </a>
                </span>
                    <a (click)="ClientList()">
                        <span class="hide-txt">Client List</span>
                        <img class="more-icon" src="assets/Icons/icon/clientList.png" alt="" height="25px" width="25px">
                        <span class="name-label">Client List</span>
                    </a>
                </div>
            </a>

            <a id="users">
                <span class="icons-back">
                    <img src="assets/Icons/icon/user.png" alt="" height="25px" width="25px">
                    <span class="menu-name-label">Users</span>
                </span>
                <span class="button-name">Users</span>
                <span class="down-arrow">
                    <img src="assets/Icons/icon/downArrow.png" alt="" height="10px" width="10px">
                </span>
                <div class="sub-menu" id="u-submenu">
                    <span *ngIf="userType === 'Admin' || userType === 'User'">
                    <a (click)="Users()">
                        <span class="hide-txt">Add User</span>
                        <img class="more-icon" src="../../assets/Icons/icon/addUser.png" alt="" height="25px"
                            width="25px">
                        <span class="name-label">Add User</span>
                    </a>
                </span>
                    <a (click)="UsersList()">
                        <span class="hide-txt">User List</span>
                        <img class="more-icon" src="../../assets/Icons/icon/userList.png" alt="" height="25px"
                            width="25px">
                        <span class="name-label">User List</span>
                    </a>
                </div>
            </a>

            <a id="projects">
                <span class="icons-back">
                    <img src="assets/Icons/icon/project.png" alt="" height="25px" width="25px">
                    <span class="menu-name-label">Projects</span>
                </span>
                <span class="button-name">Projects</span>
                <span class="down-arrow">
                    <img src="assets/Icons/icon/downArrow.png" alt="" height="10px" width="10px">
                </span>
                <div class="sub-menu" id="p-submenu">
                    <span *ngIf="userType === 'Admin' || userType === 'User'">
                    <a (click)="Project()">
                        <span class="hide-txt">Add Project</span>
                        <img class="more-icon" src="../../assets/Icons/icon/addProject.png" alt="" height="25px"
                            width="25px">
                        <span class="name-label">Add Project</span>
                    </a>
                </span>
                    <a (click)="ProjectList()">
                        <span class="hide-txt">Project List</span>
                        <img class="more-icon" src="../../assets/Icons/icon/projectList.png" alt="" height="25px"
                            width="25px">
                        <span class="name-label">Project List</span>
                    </a>
                </div>
            </a>


            <a (click)="Annotations()" id="annotations">
                <span class="icons-back">
                    <img src="assets/Icons/icon/annotation.png" alt="" height="25px" width="25px">
                    <span class="menu-name-label">Annotation</span>
                </span>
                <span class="button-name">Annotation</span></a>
        </div>
    </div>
</div> -->
<div class="col-lg-10" id="col-lg-10">
    <router-outlet></router-outlet>
</div>

</div>