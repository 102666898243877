<nav class="navbar navbar-expand-lg navbar-light bg-dark py-0">
  <div class="container-fluid" style="height: 35px;">
    <!-- <a class="navbar-brand" href="#">Home</a> -->
    <p class="mb-0" style="color: white;">Project Name</p>
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
    <div class="collapse navbar-collapse" id="navbarNavDropdown" style="margin-left: 30px;">
      <ul class="navbar-nav" style="font-size: 15px;">
        <!-- <li class="nav-item">
       
          </li> -->

        <li class="nav-item dropdown" (mouseover)="Navigatehover('show')" (mouseleave)="Navigatehover('hide')">
          <a class="nav-link dropdown-toggle py-1" href="#" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" style="color: aliceblue;">
            Navigate to Site
          </a>
          <ul class="dropdown-menu {{IsShowViewNavigate}}" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#">5151_Bannock_St_Chimney _Building_CAD</a></li>

          </ul>
        </li>
        <li class="nav-item dropdown" (mouseover)="Viewhover('show')" (mouseleave)="Viewhover('hide')">
          <a class="nav-link dropdown-toggle py-1" href="#" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" style="color: aliceblue;">
            View
          </a>
          <ul class="dropdown-menu {{IsShowView}}" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#">Top-Down-View</a></li>
            <li><a class="dropdown-item" href="#">Top-Down- all sites</a></li>
            <li><a class="dropdown-item" href="#">Reset View</a></li>
            <li><a class="dropdown-item" href="#">Save Current View as site default</a></li>
          </ul>
        </li>
        <!-- <li class="nav-item dropdown"
        (mouseover)="isOpened = true"
          (mouseleave)="isOpened = false" ngClass="isOpened ? 'dropdown-menu show' : 'dropdown-menu'" >
          <a class="nav-link dropdown-toggle py-1" href="#" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" style="color: aliceblue;">
            Features
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">World Globe</a></li>
            <li><a class="dropdown-item" href="#">3D Models</a></li>
            <li><a class="dropdown-item" href="#">Photo Viewer</a></li>
            <li><a class="dropdown-item" href="#">Camera Positions</a></li>
            <li><a class="dropdown-item" href="#">Site Markers</a></li>
            <li><a class="dropdown-item" href="#">Annotations</a></li>
          </ul>
        </li> -->
          <li class="nav-item dropdown"
        (mouseover)="hover('show')" (mouseleave)="hover('hide')"
          >
          <a class="nav-link dropdown-toggle py-1" href="#" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" style="color: aliceblue;">
            Features
          </a>
          <ul class="dropdown-menu {{IsShow}}">
            <li><a class="dropdown-item" href="#">World Globe</a></li>
            <li><a class="dropdown-item" href="#">3D Models</a></li>
            <li><a class="dropdown-item" href="#">Photo Viewer</a></li>
            <li><a class="dropdown-item" href="#">Camera Positions</a></li>
            <li><a class="dropdown-item" href="#">Site Markers</a></li>
            <li><a class="dropdown-item" href="#">Annotations</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <button type="button" class="btn" style="color: aliceblue;" > Sign Out</button> -->
    <a class="nav-link active py-1" aria-current="page" href="#" style="color: aliceblue; margin-top: 12px"><i class="fa fa-home"
        aria-hidden="true"></i></a>
    <i class="nav-icon fas fa-power-off red"  (click)="SignOut()" style="color: red; margin-right: 2%;"></i>
  </div>
</nav>
<div style="    
position: absolute;
z-index: 99;
left: 2px;
top:40px;">
  <ac-toolbar-button title="Select Tools" matTooltipPosition="right"
    [iconUrl]="'assets/Logos/12.png'" (onClick)="ToggleHandler()">
  </ac-toolbar-button>
</div>
<div *ngIf="coolapsed" style="position: absolute;

 top: 125px;
 z-index: 99;
 left: 3px;
 background-color: white;
 width: 250px;
 color: black;">
  <h3>Tools</h3>
  <div class="row">
    <div class="col-sm-2">
      <ac-toolbar-button style="width: 0% !important;" matTooltip="Range And Bearing" title="Range And Bearing" matTooltipPosition="right"
      [iconUrl]="'assets/Icons/Blue/Blue Measurement Toolmdpi.png'" (onClick)="createRangeAndBearing()" >
    </ac-toolbar-button>
    </div>
    <div class="col-sm-2">
      <ac-toolbar-button mat-raised-button matTooltip="Zoom To Rectangle" title="Zoom To Rectangle"
      matTooltipPosition="right" [iconUrl]="'assets/Icons/Blue/Blue Zoom Tool.png'" (onClick)="zoomToRectangle()">
    </ac-toolbar-button>
    </div>
    <div class="col-sm-2">
      <ac-toolbar-button mat-raised-button title="Draw Rectangle" matTooltipPosition="right"
      [iconUrl]="'assets/Icons/Blue/Blue Rectangle Tool.png'" (onClick)="drawRectangle()">
    </ac-toolbar-button>
    </div>
    <div class="col-sm-2">
      <ac-toolbar-button mat-raised-button title="Draw Circle" matTooltipPosition="right"
    [iconUrl]="'assets/Icons/Blue/Blue Circle Tool.png'" (onClick)="drawCircle()">
  </ac-toolbar-button>
    </div>
    <div class="col-sm-2">
      <ac-toolbar-button mat-raised-button title="Draw Ellipse" matTooltipPosition="right"
      [iconUrl]="'assets/Icons/Blue/Blue Ellipse Tool.png'" (onClick)="drawEllipse()">
    </ac-toolbar-button>
    </div>
   

    </div>
      

    <div class="row mt-2">
      <div class="col-sm-2">
        <ac-toolbar-button mat-raised-button title="Draw Polygon" matTooltipPosition="right"
        [iconUrl]="'assets/Icons/Blue/Blue Polygon Toolmdpi.png'" (onClick)="drawPolygon()">
      </ac-toolbar-button>
      </div>

      <div class="col-sm-2">
        <ac-toolbar-button mat-raised-button title="Draw hippodrome" matTooltipPosition="right"
        [iconUrl]="'assets/Icons/Blue/Blue Hippodrome Tool.png'" (onClick)="drawHippodrome()">
      </ac-toolbar-button>
      </div>

      <div class="col-sm-2">
        <ac-toolbar-button mat-raised-button title="Draw Line" matTooltipPosition="right"
        [iconUrl]="'assets/Icons/Blue/BluePolylineTool.png'" (onClick)="drawpolyLine()">
      </ac-toolbar-button>
      </div>

       <div class="col-sm-2">
        <ac-toolbar-button mat-raised-button title="Point Editor" matTooltipPosition="right"
        [iconUrl]="'assets/Icons/Blue/Blue Map PIn Toolmdpi.png'" (onClick)="startPoint()">
      </ac-toolbar-button>
      </div>

    </div>

  
</div>

<ac-map>


  <ac-layer acFor="let track of entities$" [context]="this">
    <ac-model-desc props="{
                              position: track.position,
                              uri: 'assets/CesiumAir/5151.dae',
														}">
    </ac-model-desc>
  </ac-layer>


  <!-- <ac-layer acFor="let billboard of billboards$" [context]="this" [debug]="true">
      <ac-billboard-desc props="{ position: billboard.position,
        image : 'https://preview.ibb.co/cpDuwF/angular_cesium.png',
        scale: 0.05,
        }">
      </ac-billboard-desc>
    </ac-layer> -->


  <div class="toolTip mt-5">

    <!-- <div>
      <ac-toolbar-button matTooltip="Range And Bearing" title="Range And Bearing" matTooltipPosition="right"
        [iconUrl]="'assets/ruler.svg'" (onClick)="createRangeAndBearing()">
      </ac-toolbar-button>
    </div>
    <div>
      <ac-toolbar-button mat-raised-button matTooltip="Zoom To Rectangle" title="Zoom To Rectangle"
        matTooltipPosition="right" [iconUrl]="'assets/select.svg'" (onClick)="zoomToRectangle()">
      </ac-toolbar-button>
    </div>
    <div>
      <ac-toolbar-button mat-raised-button title="Draw Rectangle" matTooltipPosition="right"
        [iconUrl]="'assets/rectangle.svg'" (onClick)="drawRectangle()">
      </ac-toolbar-button>
    </div>
    <div>
      <ac-toolbar-button mat-raised-button title="Draw Circle" matTooltipPosition="right"
        [iconUrl]="'assets/circle.svg'" (onClick)="drawCircle()">
      </ac-toolbar-button>
    </div>
    <div>
      <ac-toolbar-button mat-raised-button title="Draw Ellipse" matTooltipPosition="right"
        [iconUrl]="'assets/ellipse.svg'" (onClick)="drawEllipse()">
      </ac-toolbar-button>
    </div>
    <div>
      <ac-toolbar-button mat-raised-button title="Draw Polygon" matTooltipPosition="right"
        [iconUrl]="'assets/polygon.svg'" (onClick)="drawPolygon()">
      </ac-toolbar-button>
    </div>
    <div>
      <ac-toolbar-button mat-raised-button title="Draw hippodrome" matTooltipPosition="right"
        [iconUrl]="'assets/hippodrome.svg'" (onClick)="drawHippodrome()">
      </ac-toolbar-button>
    </div>

    <div>
      <ac-toolbar-button mat-raised-button title="Draw Line" matTooltipPosition="right"
        [iconUrl]="'assets/Logos/BlueMeasurment Tool.png.png'" (onClick)="drawpolyLine()">
      </ac-toolbar-button>
    </div>

    <div>
      <ac-toolbar-button mat-raised-button title="Point Editor" matTooltipPosition="right"
        [iconUrl]="'assets/Logos/BlueMeasurment Tool.png.png'" (onClick)="startPoint()">
      </ac-toolbar-button>
    </div> -->
    <!-- <ac-map-layer-provider *ngIf="appSettingsService.showMapLayer" [provider]="MapLayerProviderOptions.ArcGisMapServer"
     [options]="{
          url : 'https://beta.cesium.com/api/assets/138507?access_tokenyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZjY5N2IwNy05ZGRmLTRhNzAtYjYwYi1lYTNiZjNlZDVkNzEiLCJpZCI6MTk5NTgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzY1MjQ4MTJ9.aSMhb-9zXpVv_dZEAxrg9qB9lYgCQKMsiKRwBwT9MH4'
      }">
</ac-map-layer-provider> -->
    <!-- <ac-3d-tile-layer
        
        [options]="{
          url: 'https://cesium.com/ion/assets/64732?eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZjY5N2IwNy05ZGRmLTRhNzAtYjYwYi1lYTNiZjNlZDVkNzEiLCJpZCI6MTk5NTgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzY1MjQ4MTJ9.aSMhb-9zXpVv_dZEAxrg9qB9lYgCQKMsiKRwBwT9MH4'
        }">
    </ac-3d-tile-layer> -->

    <!-- <div class="w3-sidebar w3-bar-block w3-collapse " [ngClass]="[ isFavorite ? 'display' : 'displayShow']"  
     style="width:200px;right:0;" >
      <button class="w3-bar-item w3-button w3-hide-large"
      >Close &times;</button>
      <a href="#" class="w3-bar-item w3-button">Link 1</a>
      <a href="#" class="w3-bar-item w3-button">Link 2</a>
      <a href="#" class="w3-bar-item w3-button">Link 3</a>
    </div>
    
    <div class="w3-main" style="margin-right:200px">
    <div class="w3-teal">
      <button class="w3-button w3-teal w3-xlarge w3-right w3-hide-large" (click)="open()" >&#9776;</button>
      <div class="w3-container">
        <h2>My Page</h2>
      </div>
    </div>
    
    </div> -->

    <!-- <div>
     <ac-toolbar-button mat-raised-button title="Draw test" matTooltipPosition="right" [iconUrl]="'assets/hippodrome.svg'"
       (onClick)="startDraw()" mat-raised-button title="Draw test" matTooltipPosition="right" [iconUrl]="'assets/hippodrome.svg'"
       (onClick)="startDraw()">
     </ac-toolbar-button>
   </div> -->


    <!-- <button (click)="drawCircle()">Circle</button>
   <button mat-raised-button (click)="startEdit()">Create Circle</button> -->

    <range-and-bearing #rangeAndBearing></range-and-bearing>
    <circles-editor></circles-editor>
    <rectangles-editor></rectangles-editor>
    <ellipses-editor></ellipses-editor>
    <polygons-editor></polygons-editor>
    <hippodrome-editor></hippodrome-editor>
    <polylines-editor></polylines-editor>
    <points-editor></points-editor>

    <!-- <billboard-layer-example></billboard-layer-example> -->
    <!-- <ac-drag-icon></ac-drag-icon> -->



  </div>

</ac-map>