import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppServices } from 'src/Services/App.Services';


@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private fb: FormBuilder,
    private _toastr: ToastrService,
    private router: Router,

  ) { }


  ngOnInit(): void {
    this.reactiveForm()
  }

  ForgetPass: FormGroup;

  reactiveForm() {
    this.ForgetPass = this.fb.group({
      email: ['', Validators.required],
      newpass: ['', Validators.required],
      pin:['']
    });
  }


  Submit() {
debugger

    if(this.ForgetPass.controls.email.value != ""){
    debugger
    let Data: any;
    this.spinner.show();
    let body = {
      email: this.ForgetPass.controls.email.value,
      password: this.ForgetPass.controls.newpass.value,
    }
    try {
      this.userService.Loginservice(
        environment.LoginBaseURL, `api/reset-password`, body,
      )
        .subscribe(
          (response) => {

            debugger
            Data = response
            if (Data.status == 200) {
              this.router.navigateByUrl('/login');
              this._toastr.success(Data.message)
              this.spinner.hide();
            }
            else if (Data.status_code == 204) {
              this._toastr.warning(Data.message)
              this.spinner.hide();
            }
            else{
              this._toastr.warning(Data.message)
              this.spinner.hide();
            }

          },
          (error) => {
            debugger
          this._toastr.warning(error.error.message)
          this.spinner.hide();
            // this.ShowErrorMsg(error.error)
          }
        )
    }
    catch {

      this._toastr.error(this.userService.getSomethingWrong());
      this.spinner.hide();
      debugger
    }
  } else {
    if(this.ForgetPass.controls.email.value == ""){
      this._toastr.warning("email must be atleast  characters")
    }
  else if(this.ForgetPass.controls.newpass.value == ""){
      this._toastr.warning("Name must be atleast 3 characters")
    }
  }
  }

 

}
