<div id="userList-box">
    <div class="userList">
        <div class="ul-header">
            <div class="ul-text">User List</div>
        </div>
        <div class="ul-body">
            <div class="ul-container">

                <div class="container" *ngFor="let User of UserList">
                    <div class="container-img">
                        <img *ngIf="User.profile_photo_path != null;  else elseImg" src={{imageBaseUrl}}{{User.profile_photo_path}}>
                        <ng-template #elseImg>
                          <img alt="addUser" src="assets/Icons/icon/placeholder.jpg">
                      </ng-template>
                    </div>
                    <div class="container-info">
                        <span> Name: {{User.fname}} {{User.lname}}</span>
                        
                        <span *ngIf="User.is_super_admin == 1">Role: Super Admin</span>
                        <span *ngIf="User.is_admin == 1">Role: Admin</span>
                        <span *ngIf="User.is_user == 1">Role: User</span>
                        <span>Email: {{User.email}}</span>
                        <img (click)="updateUserList($event)" id="{{User.id}}"
                            src="assets/Icons/icon/edit.png" alt="addUser" height="25px" width="25px"
                            class="edit-btn">
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>