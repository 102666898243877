import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppServices } from 'src/Services/App.Services';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.GetProjectList();
  }

  clientsList = []
  ProjectList = []
  checkUser:any;
  GetProjectList() {

    debugger

    this.spinner.show()
    let Data: any;
    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/project/user/`+JSON.parse(localStorage.user).id
      )
        .subscribe(
          (response) => {

            debugger
            Data = response
            if (Data.projects.length > 0 ) {
              this.spinner.hide();
              this.ProjectList = Data.projects
              // this.clientsList = Data.clients
            }
            else {
              this.spinner.hide();
              this._toastr.warning('No Project(s) Found!')

            }

          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
          }
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }

  }


  

  Project_id: any;
  updateProject(event: any) {
    debugger
    this.Project_id = event.target.id
    this.router.navigateByUrl('/Project', { state: { val: "update", ProjectId: this.Project_id } })
  }
  // delete API
  ToggleStatus(event: any) {
    debugger
    let Project_id = event.target.id
    if(this.Project_id != undefined){
      this.SetProjectStatus()
    }
  }
  GetProjectDeleteId(event: any) {
    debugger
    this.Project_id = event.target.id
    if (this.Project_id != undefined) {
      alert("Do you really want to delete this project?");
      this.DeleteProject()
    }


  }
  DeleteProject() {

    debugger

    this.spinner.show()
    let Data: any;

    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/projects/delete/${this.Project_id}`
      )
        .subscribe(
          (response) => {

            debugger
            Data = response

            if (Data.status == "200") {
              this.spinner.hide();
              this.GetProjectList()
              this._toastr.success(Data.message)
              this.reloadCurrentRoute();
              

            }
            else {
              this.spinner.hide();
              this._toastr.warning(Data.message)

            }

          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
          }
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }

  }

reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}
  SetProjectStatus() {

    debugger

    this.spinner.show()
    let Data: any;

    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/projects/status/${this.Project_id}`
      )
        .subscribe(
          (response) => {

            debugger
            Data = response

            if (Data.status == "200") {
              this.spinner.hide();
              this.GetProjectList()
              this._toastr.success(Data.message)

            }
            else {
              this.spinner.hide();
              this._toastr.warning(Data.message)

            }

          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
          }
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }

  }

  // bulk delete
  collectDelete: any = []
  BulkDelete(event: any) {
    debugger
    let id = event.target.id
    this.collectDelete.push(id)
    console.log(this.collectDelete)
  }

  DeleteBulkProject() {

    debugger

    this.spinner.show()
    let Data: any;

    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/annotations/destroy/${this.collectDelete}`
      )
        .subscribe(
          (response) => {

            debugger
            Data = response

            if (Data.status == "200") {
              this.spinner.hide();
              this.GetProjectList()
              this._toastr.success(Data.message)

            }
            else {
              this.spinner.hide();
              this._toastr.warning(Data.message)

            }

          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)
          }
        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }

  }

  selectAll() {

    var btnChecked: any = document.getElementById("select-all-pl");
    var btn: any = btnChecked.checked;
    var chk: any = document.getElementsByName("chk");
    var intLength = chk.length;
    for (var i = 0; i < intLength; i++) {
      var check = chk[i];
      check.checked = btn;
    }
    var deleteAll: any = document.getElementById("delete-all-pl");
    deleteAll.addEventListener('click', function () {
      var tableRef: any = document.getElementById('projectList-table');
      var tableRows = tableRef.rows;
      var checkedIndexes = [];
      for (var i = 0; i < tableRows.length; i++) {
        var checkboxSelected = tableRows[i].cells[0].children[0].checked;
        if (checkboxSelected) {
          checkedIndexes.push(i);
        }
      }

      for (var k = checkedIndexes.length - 1; k >= 1; k--) {
        tableRef.deleteRow(checkedIndexes[k]);
      }
    });

  }

}
