<div id="addProject-box">
    <div class="pcontent">
        <div class="pcontent-header">
            <div class="pform-name">{{Heading}}</div>
        </div>
        <div class="pcontent-body">
            <form [formGroup]="AddProjectForm" enctype="multipart/form-data">
                <table>
                    <tr>
                        <td>
                            <label for="">Project Name</label>
                        </td>
                        <td>
                            <input type="text" formControlName="title" placeholder="Name" autocomplete="new-title">
                        </td>
                        <td>
                            <label for="" style="margin: 0;">Cesium lon ID</label>
                        </td>
                        <td>
                            <input type="text" formControlName="cesium_id"
                                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                                placeholder="ID" autocomplete="new-cesium_id">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="">Description</label>
                        </td>
                        <td>
                            <input type="text" formControlName="description" placeholder="Description"
                                autocomplete="new-desciption">
                        </td>

                        <td><label for="projectType">Project Type</label></td>
                        <td>
                            <select name="projectType" id="projectType" formControlName="projectType">
                                <option value="">Select Project Type</option>
                                <option value="3D Tile">3D Tile</option>
                                <option value="KML Model">KML Model</option>
                                <option value="Cad Model">Cad Model</option>
                                <option value="Point cloud">Point cloud</option>
                                <option value="DSM Model">DSM Model</option>
                            </select>
                        </td>
                    <tr>
                        <td>
                            <label for="">Status</label>
                        </td>
                        <td (change)="check($event)">
                            <input type="radio" name="status" id="Enable"><label for="">Enable</label>
                            <input type="radio" name="status" id="Disable"><label for="">Disable</label>
                        </td>

                        <td *ngIf="Heading != 'Update Project' || is_super_admin == 1"><label for="clients">Clients</label></td>
                        <td *ngIf="Heading != 'Update Project' || is_super_admin == 1">
                            <!-- <select id="CLIENTS" (change)="ChangeClient($event)">
                                <option *ngFor="let ClientDrop of ClientList" [value]="ClientDrop.id">
                                    {{ClientDrop.description}}</option>
                            </select> -->
                            <ng-multiselect-dropdown [placeholder]="'Please select clients'" 
                            [settings]="dropdownSettings"
                            [data]="ClientList"
                            (onSelect)="onItemSelect($event)"
                     
                            (onDeSelect)="onItemDeSelect($event)"
                            >
                            </ng-multiselect-dropdown>

                        </td>
                    </tr>
                </table>
                <div>
                    <label for="" class="proj-imgs">Project Image</label>
                    <div class="full">
                        <label for="file-upload" class="custom-file-upload">{{ImgLabel}}</label>
                        <input #imageInput accept="image/*" (change)="processFile($event)" id="file-upload"
                            type="file" />
                    </div>
                </div>
                <!-- <div *ngIf="this.Heading == 'Update Project' ">
                    <button type="button" (click)="AddClient()">Add Client</button>
                </div> -->
                <div class="submit-class">
                    <!-- <div *ngIf="this.Heading == 'Update Project' ">
                        <button type="button" (click)="AddClient()">Add Project</button>
                    </div> -->
                    <button type="submit" (click)="SubmitForClient()">Submit</button>
                </div>

            </form>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>