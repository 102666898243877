import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  CameraService,
  CesiumService,
  CirclesEditorService,
  EllipsesEditorService,
  RectanglesEditorService,
  HippodromeEditorService,
  PointsEditorService,
  PolygonsEditorService,
  PolylineEditorObservable,
  RangeAndBearingComponent,
  ZoomToRectangleService,
  LabelProps,
  CircleEditUpdate,
  CircleEditorObservable,
  ViewerConfiguration
} from 'angular-cesium';
import { AppServices } from 'src/Services/App.Services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [CirclesEditorService],
  // providers:[CameraService,PointsEditorService,CirclesEditorService,CesiumService
  // ,ZoomToRectangleService,EllipsesEditorService,PolygonsEditorService,
  // RectanglesEditorService,HippodromeEditorService]
})
export class AppComponent {


  editing$: CircleEditorObservable;
  enableEditing = true;
  editing = ""
  rnb: any;
  // @ViewChild('rangeAndBearing') private rangeAndBearing: RangeAndBearingComponent;
  @ViewChild('rangeAndBearing') private rangeAndBearing :any = RangeAndBearingComponent;
  constructor(
    private router: Router, 
    // private cameraService: CameraService,
    // private zoomToRectangleService: ZoomToRectangleService,
    // private cesiumService: CesiumService,
    // private pointEditor: PointsEditorService,
    // private viewerConf: ViewerConfiguration,
     private circlesEditor: CirclesEditorService,
    //  private ellipsesEditor: EllipsesEditorService,
    // private polygonsEditor: PolygonsEditorService,
    // private rectanglesEditor: RectanglesEditorService,
    // private hippodromesEditor: HippodromeEditorService,
    private userService: AppServices,
  ){
    // viewerConf.viewerOptions = {
    //   selectionIndicator: false,
    //   timeline: false,
    //   infoBox: false,
    //   fullscreenButton: false,
    //   baseLayerPicker: false,
    //   animation: false,
    //   homeButton: false,
    //   geocoder: true,
    //   navigationHelpButton: false,
    //   navigationInstructionsInitiallyVisible: false,
    //   mapMode2D: Cesium.MapMode2D.ROTATE,
    // }
  }
  Token:any
  ngOnInit() {
    debugger
  //  this.Token = localStorage.getItem('accesstoken')
    this.userService.populate()
  }

  ChangeMap(){
    debugger
    this.router.navigateByUrl('/Map')
  }

  isShown:boolean = false
  isShownMap:boolean = true
  Client(){
     this.router.navigateByUrl('/Client')
  }
//   createRangeAndBearing() {
//     if (this.rnb) {
//       this.rnb.dispose();
//     }
//      this.rnb = this.rangeAndBearing.create();

// } 

// isShownLogin:boolean = true
// isShownDashboard:boolean = false
// isShownMap:boolean = false
// Login(){
// debugger
// this.isShownLogin = false
// this.isShownDashboard = true
// }
// ChangeMap(){
//   debugger
//   this.isShownDashboard = false
//   this.isShownMap = true
// }

// startEdit() {
//   debugger
//   if (this.editing$) {
//     this.stopEdit();
//   }
//   this.editing$ = this.circlesEditor.create();
//   this.editing$.subscribe((editUpdate: CircleEditUpdate) => {
//     // current edit value
//     console.log(editUpdate);
//     // or
//     console.log('center', this.editing$.getCenter());
//     console.log('radius', this.editing$.getRadius());
//   });
// }

// stopEdit() {
//   if (this.editing$) {
//     this.editing$.dispose();
//   //  this.editing$ = 0;
//   }
// }

// editFromExisting() {
//   if (this.editing$) {
//     this.stopEdit();
//   }
//   this.editing$ = this.circlesEditor.edit(Cesium.Cartesian3.fromDegrees(-70, 0), 800000);
//   this.editing$.subscribe((editUpdate: CircleEditUpdate) => {
//     console.log(editUpdate);
//     console.log('center', this.editing$.getCenter());
//     console.log('radius', this.editing$.getRadius());
//   });
//   this.editing$.setLabelsRenderFn((update: CircleEditUpdate) => {
//     const newLabels: LabelProps[] = [];
//     newLabels.push(
//       {
//         text: 'Center',
//         scale: 0.6,
//         eyeOffset: new Cesium.Cartesian3(10, 10, -1000),
//         fillColor: Cesium.Color.BLUE,
//       },
//       // {
//       //   text: Math.round(update.radius).toString(),
//       //   scale: 0.6,
//       //   eyeOffset: new Cesium.Cartesian3(10, 10, -1000),
//       //   fillColor: Cesium.Color.RED,
//       // },
//     );
//     return newLabels;
//   });
  
//   setTimeout(() =>
//     this.editing$.updateLabels(
//       this.editing$.getLabels().map(label => {
//         label.text += '*';
//         label.fillColor = Cesium.Color.YELLOW;
//         return label;
//       })
//     ), 2000);
// }

// toggleEnableEditing() {
//   // Only effects if in edit mode (all polygon points were created)
//   if (!this.editing$) {
//     return;
//   }
//   this.enableEditing = !this.enableEditing;
//   if (this.enableEditing) {
//     this.editing$.enable();
//   } else {
//     this.editing$.disable();
//   }
// }

// updateCircleManually() {
//   if (this.editing$) {
//     this.editing$.setManually(Cesium.Cartesian3.fromDegrees(-80, 0), 500000);
//   }
// }

}