<section class="vh-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 text-black">

                <!-- <div class="px-5 ms-xl-4">


                    <img src="assets/LOGO_5.png" alt="Login image" style="object-fit: cover; object-position: left;">

                    <span class="h1 fw-bold mb-0"></span>
                </div> -->

                <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-5 pt-xl-0 mt-xl-n5">

                    <form [formGroup]="RegisterForm" style="width: 23rem;">

                        <h3 class="fw-normal mb-3 pb-3 mt-5" style="letter-spacing: 1px; font-size: 40px; "><b>Register</b> </h3>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-outline mb-1">
                                    <label class="form-label" for="form2Example18">Full Name</label>
                                    <input type="name" id="form2Example18" formControlName="name" autocomplete="off"
                                        class="form-control-lg" />


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-outline mb-1">
                                    <label class="form-label" for="form2Example18" style="margin-left: 50% !important;">Email</label>
                                    <input type="email" id="form2Example18" autocomplete="off" formControlName="email" style="margin-left: 50% !important;"
                                   
                                        class="form-control-lg" />


                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-outline mb-1">
                                    <label class="form-label" for="form2Example18">Password</label>
                                    
                                    <input type="password" id="form2Example18" formControlName="password" autocomplete="off"
                                        class=" form-control-lg" />


                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-outline mb-1">
                                    <label class="form-label" for="form2Example18" style="margin-left: 50% !important;">Confirm</label>
                                    <input type="password" id="form2Example18" formControlName="password_confirmation" autocomplete="off" style="margin-left: 50% !important;"
                                        class=" form-control-lg" />


                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-outline mb-1">
                                    <label for="LANGUAGE">Role</label>
                                    <select class="form-select bg-transparent form-label" id="LANGUAGE" formControlName="role">
                                        <option value="admin">Admin</option>
                                        <option value="user">User</option>
                                    </select>
                               

                                </div>

                                </div>
                                </div>

                    
                        <div class="pt-1 mt-3">
                            <button class="btn btn-info btn-lg btn-block" type="button"
                                (click)="SubmitForRegister()">Submit</button>
                        </div>

                        <!-- <p class="small pb-lg-2"><a class="text-muted" href="#!">Forgot password?</a></p> -->
                        <p>Already have an account?  <u style="color: blueviolet;" (click)="Login()">Login here</u>
                        </p>
                     

                    </form>

                </div>

            </div>
            <div class="col-sm-6 px-0 d-none d-sm-block">
                <!-- <img src="https://forum.huawei.com/enterprise/en/data/attachment/forum/201909/27/100129gvscd0mzd7ul9ukr.jpg?GIS.JPG" alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;"> -->
                <img src="assets/SeraPoint .png" alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">

                <!-- <img src="assets/cesium.PNG"
                    alt="Login image" class=" vh-50" style="object-fit: cover; object-position: left;">
                    <img src="assets/LOGO_5.png" alt="Login image" style="object-fit: cover; object-position: left;"> -->
            </div>
        </div>
    </div>
</section>

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>