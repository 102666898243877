import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpResponse, HttpEventType, HttpClientModule } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';

// import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AppServices {
  public reloadPage: number = 0;
  public LastKeyPressedOn: Date = new Date();
  private currentUserSubject = new BehaviorSubject<any>({} as any);
  public currentUser = this.currentUserSubject.asObservable().pipe();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

 
  constructor(
    private http: HttpClient,
    private router: Router,
    // private _toastr: ToastrService
  ) { }

  DataFromEDI: any;
  token: any

  populate(contentType: string = 'json', Accept: boolean = false) {



    if (localStorage.getItem('accesstoken') != undefined) {
      this.router.navigateByUrl('/Dashboard');
      window.localStorage.loggedin_user.id = JSON.parse(localStorage.user).id;
    } else {
      // Remove any potential remnants of previous auth states
      this.router.navigateByUrl('/login')
    
    }

   

  }

  HttpHeadersWithToke(contentType: string = 'json', Accept: boolean = false) {
  
    this.token = window.localStorage['accesstoken'];
    return new HttpHeaders({

      'Content-Type': 'application/' + contentType,
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + this.token,
  
    });
  }

 

  getHttpHeaders(contentType: string = 'json', Accept: boolean = false) {

  
   
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',

      });
   

  }

  Getservice(Url: string, method: string, contentType: string = "json",) {

    const httpOptions = { headers: this.HttpHeadersWithToke(contentType) };
    return this.http.get(Url + method, httpOptions);

  }

  Postservice(Url: string, method: string, body: any, httpAttribute: string = "post", contentType: string = "json",) {

    const httpOptions = { headers: this.HttpHeadersWithToke(contentType) };

    if (httpAttribute == "put") {
      return this.http.put(Url + method, body,httpOptions);
    }
    else if (httpAttribute == "delete") {
      return this.http.delete(Url + method, httpOptions);
    }
    else if (httpAttribute == "patch") {
      return this.http.patch(Url + method, body, httpOptions);
    }
    else {
      return this.http.post(Url + method, body, httpOptions);
    }

  }

  Loginservice(Url: string, method: string, body: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/' + 'json',
        'Access-Control-Allow-Origin': '*',
      })
    };

    return this.http.post(Url + method, body, httpOptions);

  }


  
  dateFormat(inputDate: any) {
    if (inputDate != null) {
      if (inputDate.length == 10) inputDate += " 00:00:00";
      var date = new Date(inputDate);
      if (!isNaN(date.getTime())) {
        if (date.getFullYear() < 1901) return "";

        // Months use 0 index.
        let Month = "";
        let b;

        if (date.getMonth() + 1 < 9) {
          b = date.getMonth() + 1;
          Month = "0" + b;
        } else {
          Month = (date.getMonth() + 1).toString();
        }
        let ddate = "";
        if (date.getDate() < 9) {
          ddate = "0" + date.getDate();
        } else {
          ddate = date.getDate().toString();
        }

        return ddate + "/" + Month + "/" + date.getFullYear();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  dateFormatYearFirst(inputDate: any) {
    if (inputDate != null) {
      if (inputDate.length == 10) inputDate += " 00:00:00";
      var date = new Date(inputDate);
      if (!isNaN(date.getTime())) {
        if (date.getFullYear() < 1901) return "";

        // Months use 0 index.
        let Month = "";
        let b;

        if (date.getMonth() + 1 < 9) {
          b = date.getMonth() + 1;
          Month = "0" + b;
        } else {
          Month = (date.getMonth() + 1).toString();
        }
        let ddate = "";
        if (date.getDate() < 9) {
          ddate = "0" + date.getDate();
        } else {
          ddate = date.getDate().toString();
        }

        return date.getFullYear() + "-" + Month + "-" + ddate;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  dateFormatmmddyyyy(inputDate: any) {
    if (inputDate != null) {
      if (inputDate.length == 10) inputDate += " 00:00:00";
      var date = new Date(inputDate);
      if (!isNaN(date.getTime())) {
        if (date.getFullYear() < 1899) return "";

        // Months use 0 index.
        let Month = "";
        let b;

        if (date.getMonth() + 1 < 9) {
          b = date.getMonth() + 1;
          Month = "0" + b;
        } else {
          Month = (date.getMonth() + 1).toString();
        }
        let ddate = "";
        if (date.getDate() < 9) {
          ddate = "0" + date.getDate();
        } else {
          ddate = date.getDate().toString();
        }

        return Month + "/" + ddate + "/" + date.getFullYear();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  FormateDatetimeForLocal(GetDate: any) {
    let s = GetDate.toString();
    if (s.includes("AM") || s.includes("PM")) {
      return GetDate;
    }
    const D1 = GetDate.split("T");
    const Dat = D1[0];
    let FormateDate = Dat.split("-");
    let FinalDate =
      FormateDate[1] + "/" + FormateDate[2] + "/" + FormateDate[0];
    var Tm = D1[1].split(".");
    Tm = Tm[0];
    var TSplit = Tm.split(":");
    var Hour = 0;
    if (TSplit[0] > 12) {
      Hour = TSplit[0] - 12;
      Tm = Hour + ":" + TSplit[1] + " " + "PM";
    } else {
      Tm = TSplit[0] + ":" + TSplit[1] + " " + "AM";
    }
    if (FinalDate == "01/01/1900") {
      return "";
    }
    var d = FinalDate; //new Date(FinalDate + " " + Tm + " UTC") + " " + Tm;;
    return d;

  }


  // record is deleted message 
  getDeletedMessage() {
    return "Record is deleted";
  }

  getUpdateSuccess() {

    return 'Record Updated Successfully'
  }
  getSaveSuccess() {
    return 'Record Saved Successfully'
  }
  getSomethingWrong() {
    return "Someting went wrong";
  }
  getDuplicateID() {
    return "Please Fill the Field"
  }
  getRequiredMessage() {
    return "Please fill the required field"
  }
  getSelectRow() {
    return "Select a row"
  }
  getInsertRecord() {
    return "Record Inserted"
  }
  DynamicWarning(Msg : string) {
    // return this._toastr.warning(Msg, '')
  }



  RemoveDecimal(Value: any) {
    ;
    Value = Value.toString()
    if (Value.includes('.')) {
      let WithOutDecimal = Value.split('.')
      let FinalValue = WithOutDecimal[0] + WithOutDecimal[1]
      return FinalValue
    }
    else {
      return Value;
    }
  }

 



}



