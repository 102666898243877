<div id="addClient-box">
    <div class="content">
        <div class="content-header">
            <div class="form-name">{{Heading}}</div>
        </div>
        <div class="content-body">
            <form [formGroup]="UsersForm" enctype="multipart/form-data">
                <table>
                    <tr>
                        <td>
                            <label for="">Company Name</label>
                        </td>
                        <td>
                            <input formControlName="title" type="text" placeholder="Title">
                        </td>
                        <td>
                            <label for="">Contact Person</label>
                        </td>
                        <td>
                            <input type="text" formControlName="contact_person" placeholder="Name" autocomplete="new-contect_person">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="">Phone</label>
                        </td>
                        <td>
                            <input type="tel" formControlName="phone"
                                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                                placeholder="Number" autocomplete="new-phone">
                        </td>
                        <td>
                            <label for="">Description</label>
                        </td>
                        <td>
                            <input type="text" formControlName="description" placeholder="About" autocomplete="new-description">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="">Email</label>
                        </td>
                        <td>
                            <input type="email" formControlName="email" placeholder="Email Address" autocomplete="new-email">
                        </td>
                        <td>
                            <label for="">Status</label>
                        </td>
                        <td (change)="check($event)">
                            <input type="radio" name="status" id="Enable"><label for="">Enable</label>
                            <input type="radio" name="status" id="Disable"><label for="">Disable</label>
                        </td>
                    </tr>                  
                </table>
                <div class="full">
                    <label for="file-upload" class="custom-file-upload">{{ImgLabel}}</label>
                    <input #imageInput accept="image/*" name="client_image" (change)="processFile($event)" id="file-upload"
                        type="file" />
                </div>
                <!-- <div *ngIf="this.Heading == 'Update Client' ">
                    <button type="button" (click)="AddClient()">Add Client</button>
                </div> -->
                <div class="submit">
                    <!-- <div *ngIf="this.Heading == 'Update Client' ">
                        <button type="button" (click)="AddClient()">Add Client</button>
                    </div> -->
                    <button type="submit" (click)="SubmitForClient()">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>