<div class="PList" id="PList">
    <div class="pList-container">
        <div class="pList-header">
            <div class="pList-name">Project List</div>
        </div>
        <div class="pList-body">
            <div class="selects">
                <div class="record">
                    <label for="record">Record</label>
                    <select name="record" id="record">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div class="search">
                    <label for="" class="search">Search</label>
                    <input type="search" name="" id="" placeholder="Search here">
                </div>
            </div>
            <table id="projectList-table">
                <thead>
                    <tr>
                        <th><input type="checkbox" id="select-all-pl" (click)="selectAll()">Select</th>
                        <th>Project Name</th>
                        <th>Clients</th>
                        <th>Cesium Ion ID</th>
                        <th>Status</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <div class="tbody">
                        <tr *ngFor="let Projects of ProjectList">
                            <td><input type="checkbox" id="{{Projects.id}}" (change)="BulkDelete($event)" name="chk"></td>
                            <td>{{Projects.title}}</td>
                            <td>
                                <span *ngIf="Projects.ctitle != ''">{{Projects.ctitle}}</span>
                                <span *ngFor="let Clients of Projects.clients">{{Clients.title}},<br></span>
                            </td>
                            <td>{{Projects.cesium_id}}</td>
                            <td><label class="switch">
                                    <!-- <input type="checkbox" id="{{Projects.id}}" (click)="ToggleStatus($event)" value="{{Projects.status}}">
                                    <span class="slider round"></span> -->
                                <span *ngIf="Projects.status == 1" style="color:green">Enabled</span>
                                <span *ngIf="Projects.status == 0" style="color:red">Disabled</span>
                                </label>
                            </td>
                            <td>
                                <img (click)="updateProject($event)" id="{{Projects.id}}"
                                    src="assets/Icons/icon/edit.png" alt="" height="25px" width="25px">
                            </td>
                            <td>
                                <img (click)="GetProjectDeleteId($event)" id="{{Projects.id}}" src="assets/Icons/icon/delete.png" alt="" height="25px" width="25px">
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
            <!-- <div class="select-all" id="delete-all-pl" (click)="DeleteBulkProject()">
                <div class="del" >
                    <img src="assets/Icons/icon/deleteTable.png" alt="" height="25px" width="25px"><label for="">Delete
                        Selected</label>
                </div>
                <div class="pagination" id="pl-pagination">
                    <a href="">&laquo;</a>
                    <a href="" class="active">1</a>
                    <a href="">&raquo;</a>
                </div>
            </div> -->
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


