<div>
    <section class="vh-100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6 text-black" style="background-color: aliceblue;">
      
              <div class="px-5 ms-xl-4">
              
              
                <img src="assets/LOGO_5.png" alt="Login image"  style="object-fit: cover; object-position: left;">
              
                <span class="h1 fw-bold mb-0"></span>
              </div>
      
              <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-5 pt-xl-0 mt-xl-n5">
      
                <form [formGroup]="LoginForm" style="width: 23rem;">
      
                  <h3 class="fw-normal mb-3 pb-3 mt-5" style="letter-spacing: 1px; font-size: 40px; "><b>Log in</b> </h3>
      
                  <div class="form-outline mb-4">
                    <input type="email" id="form2Example18" autocomplete="off" formControlName="email" class="form-control form-control-lg" />
                    <label class="form-label" for="form2Example18">Email address</label>
                  </div>
      
                  <div class="form-outline mb-4">
                    <input type="password" autocomplete="off" id="form2Example28" formControlName="password" class="form-control form-control-lg" />
                    <label class="form-label" for="form2Example28">Password</label>
                  </div>
      
                  <div class="pt-1 mb-4 ml-5"> 
                    <button class="btn btn-info btn-lg btn-block" type="submit"  (click)="Login()">Submit</button>
                  </div>
      
                  <p class="small pb-lg-2"><a class="text-muted" [routerLink]="['/forgot-password']" >Forgot password?</a></p>
                  <!-- <p>Don't have an account? <a [routerLink]="['/register']" class="link-info">Register here</a></p> -->
      
                </form>
      
              </div>
      
            </div>
            <div class="col-sm-6 px-0 d-none d-sm-block">
              <img src="https://forum.huawei.com/enterprise/en/data/attachment/forum/201909/27/100129gvscd0mzd7ul9ukr.jpg?GIS.JPG" alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">
            </div>
          </div>
        </div>
      </section>
      </div>

      <!-- <ngx-spinner
      bdColor="rgba(0, 0, 0, 1)"
      template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />"
    >
    </ngx-spinner> -->
    <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

      <!-- <ng-template #ForgetPass>
        <div class="c4c_formbg c4c_passwordModal">
            <div class="modal-header">
                <h4 class="modal-title pull-left text-light">Forgot Password</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">  
            <span aria-hidden="true">×</span>  
          </button>
            </div>
            <div class="modal-body" [formGroup]="OTPform">
                <div class="row mt-2 pb-4">
                    <div class="col">
                        <input type="text" placeholder="Email" autocomplete="off" formControlName="email" class="c4c_email c4c_inputButtons c4c_pFontLabel w-100" id="inputPassword3">
                    </div>
                </div>
                <div class="row mt-2 justify-content-center">
                    <button class="c4c_signUp py-2 h5 w-25" (click)="SubmitForOTP()">Submit</button>
                </div>
      
            </div>
        </div>
    
         Footer not in used
        <div class="modal-footer">  
          <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>  
        </div>    
    
    </ng-template> -->