import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularCesiumModule, AngularCesiumWidgetsModule } from 'angular-cesium';
import { AuthModule } from 'src/Global/auth/auth.module';
import { Basecomponent } from 'src/Global/basecomponent/basecomponent.component';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DashboardComponent } from './Forms/dashboard/dashboard.component';
import { TestModule } from './Forms/test/test.module';
import { AppRoutingModule } from 'src/Routing/app-routing.module';
import { AppServices } from 'src/Services/App.Services';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from './material/material.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
  declarations: [
    AppComponent,
    Basecomponent,
  
  ],
  imports: [
    BrowserModule,
    MatTooltipModule,
    HttpClientModule,
    AuthModule.forRoot(), 
    AppRoutingModule,
    AngularCesiumModule.forRoot(),
    AngularCesiumWidgetsModule,
    NgMultiSelectDropDownModule.forRoot(),
    // MaterialModule,
    // TestModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-right'
    })
    // ModalModule.forRoot(),  
  ],
  exports:[],
  providers: [
    AppServices,
    HttpClient,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
