import { HttpEvent } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppServices } from 'src/Services/App.Services';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AcBillboardComponent } from 'angular-cesium';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private userService: AppServices,
    private _toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  route: any;
  Heading: string = "Add Project"
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  is_super_admin: any;


  ngOnInit(): void {
    this.reactiveForm()
    this.route = history.state;
    if (this.route.val == "update") {
      this.GetEditedData();
      this.Heading = "Update Project";
      this.is_super_admin = JSON.parse(localStorage.user).id;
    }
    this.GetClient();

    this.dropdownSettings = {

      idField: 'id',
      textField: 'title',
      enableCheckAll: false

    };


  }

  AddProjectForm: FormGroup;
  reactiveForm() {
    this.AddProjectForm = this.fb.group({


      title: ['', Validators.required],
      cesium_id: ['', Validators.required],
      description: ['', Validators.required],
      projectType: ['', Validators.required],

    });
  }

  SubmitForClient() {

    debugger
    if (this.AddProjectForm.valid) {
      this.spinner.show()
      let Data: any;
      // let body = {
      //   user_id:10,
      //   cesium_id: 3,
      //   title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   status: 1,
      //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   clients:1

      // }
      let body = {
        user_id: JSON.parse(localStorage.user).id,
        id: this.route.ProjectId,
        cesium_id: this.AddProjectForm.controls.cesium_id.value,
        title: this.AddProjectForm.controls.title.value.replace(/\s+/g, '-'),
        description: this.AddProjectForm.controls.description.value,
        status: this.statusValue,
        clients: this.result,
        project_type: this.AddProjectForm.controls.projectType.value,
        image: this.SelectedImg,
        // imageFile:this.SelectedFile

      }
      // check update or save
      let CheckReq
      if (this.Heading == "Update Project") {
        CheckReq = "update"
      } else {
        CheckReq = "store"
      }
      try {
        this.userService.Postservice(
          environment.LoginBaseURL, `api/projects/${CheckReq}`, body,
        )
          .subscribe(
            (response) => {

              debugger
              Data = response
              if (Data.status == 200) {

                this._toastr.success(Data.message)
                this.reactiveForm()
                this.ImgLabel = "Upload Image"
                this.clientId = ""
                this.spinner.hide();
                if (this.Heading == "Update Project") {
                  this.router.navigateByUrl('/Project-list')
                }
                
                this.AddProjectForm.reset();
              }
              else {
                this.spinner.hide();
                this._toastr.warning(Data.message)
              }

            },
            (error) => {
              debugger
              this.spinner.hide();
              this._toastr.warning(error.error.message)

            }
          )
      }
      catch {

        this._toastr.warning(Data.message)
        this.spinner.hide();
        debugger
      }

    }
    else {

      if (this.AddProjectForm.controls.title.value == "") {
        this._toastr.warning("Project name field is required")
      }

      else if (this.AddProjectForm.controls.description.value == "") {
        this._toastr.warning("Description field is required")

      }
      else if (this.AddProjectForm.controls.cesium_id.value == "") {
        this._toastr.warning("cesium id field is required")

      }


    }

  }

  AddClient() {
    this.reactiveForm()
    this.Heading = "Add Project"

  }


  statusValue: any
  check(event: any) {
    debugger
    if (event.target.id == "Enable") {
      this.statusValue = 1
    }
    else {
      this.statusValue = 0
    }

  }

  clientId: any
  ChangeClient(event: any) {
    this.clientId = event.target.value
  }


  clientArray = {}
  result: any = []
  onItemSelect(title: any) {
    debugger

    this.result.push(title.id)
    console.log(this.result);

  }
  onItemDeSelect(title: any) {
    debugger
    const index: number = this.result.indexOf(title.id);
    if (index !== -1) {
        this.result.splice(index, 1);
    }   
    // this.result.removeItem(title.id)
    console.log(this.result);
  }


  ClientList = []
  GetClient() {

    let Data: any;

    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/clients`
      )
        .subscribe(
          (response) => {

            debugger
            Data = response

            if (Data.clients.length > 0) {

              this.ClientList = Data.clients

            }
            else {

              this._toastr.warning(Data.message)

            }

          },
          (error) => {
            debugger

            this._toastr.warning(error.error.message)

          }


        )
    }
    catch {

      this._toastr.warning(Data.message)
      this.spinner.hide();
      debugger
    }




  }
  Data: any;
  GetEditedData() {

    debugger

    this.spinner.show()


    try {
      this.userService.Getservice(
        environment.LoginBaseURL, `api/projects/get/${this.route.ProjectId}`
      )
        .subscribe(
          (response) => {

            debugger
            this.Data = response
            this.PatchValues(this.Data)
            this.spinner.hide();


          },
          (error) => {
            debugger
            this.spinner.hide();
            this._toastr.warning(error.error.message)

          }


        )
    }
    catch {

      this._toastr.warning(this.Data.message)
      this.spinner.hide();
      debugger
    }


  }

  PatchValues(key: any) {
    this.AddProjectForm.patchValue({
      title: key.project.title,
    })
    this.AddProjectForm.patchValue({
      description: key.project.description,
    })
    this.AddProjectForm.patchValue({
      cesium_id: key.project.cesium_id,
    })
    this.GetClient()
    this.clientId = key.project.status

  }

  SelectedImg: any;
  SelectedFile: any;
  ImgLabel: string = "Upload Image"
  // processFile(imageInput:any){
  //   debugger
  //   if (imageInput.files[0]) {
  //   const file: File = imageInput.files[0];
  //   console.log(file)
  //   this.SelectedImg = file.name
  //   this.SelectedFile = file
  //   this.ImgLabel = file.name
  //   }
  // }


  processFile(event: any) {
    let file = event.target.files[0]
    this.ImgLabel = file.name
    // console.log("file", file)
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
    // console.log("uploadData", uploadData)
    let bytes
    const reader = new FileReader();
    debugger
    reader.onload = (e: any) => {
      bytes = e.target.result.split('base64,')[1];
      console.log("bytes", bytes)


      let Data: any;

      let body = {
        image: `data:${file.type};base64,${bytes}`
      }
      debugger

      console.log("image", body)

      this.SelectedImg = body.image

    };

    reader.readAsDataURL(file);

  }

  uploadFile(url: string, file: File) {
    debugger
    let formData = new FormData();
    formData.append('upload', file);
  }
}
