import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { authReducer } from '../../core/auth/reducers/auth.reducers';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  auth$: Observable<any>;
  constructor(private router: Router) { }

  ngOnInit() {


  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (localStorage.getItem('accesstoken') != null && localStorage.getItem('accesstoken') != undefined) {

      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
    // canActivate(
    //   route: ActivatedRouteSnapshot,
    //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //     // return this.store
    //     //       .pipe(
    //     //           select('auth'),
    //     //           tap(auth => {
    //     //              
    //     //                 console.log("auth0 " , auth)
    //     //               // if (!loggedIn) {
    //     //               //     this.router.navigateByUrl('/auth/login');
    //     //               // }
    //     //           })
    //     //       );
    //     // const authData = JSON.parse(
    //     //   String(localStorage.getItem('accesstoken')));
    //     // if(authData.length > 0){
    //     // this.store = (authData)
    //     // } else {}
    //    
    //      return this.store
    //     .pipe(
    //         select(isLoggedIn),
    //         tap(loggedIn => {

    //             if (!loggedIn) {
    //                 this.router.navigateByUrl('/auth/login');
    //             }
    //         })
    //     );
    // }
  }
}
